import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, shareReplay, tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MiscService {
  private stateCountySubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  private stateCountyDetails: any;
  private stateCountyDataLoaded = false;
  private timeZoneSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  private timeZones: any;
  private timeZonesLoaded = false;
  private serviceTypesSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  private serviceTypes: any;
  private serviceTypesLoaded = false;
  private tokenKey = 'token';

  constructor(private http: HttpClient) {}

  public fetchStateCountyDetails(): Observable<any> {
    if (!this.stateCountyDataLoaded) {
      const token = localStorage.getItem(this.tokenKey);
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });

      this.http
        .get<any>(environment.apiUrl + '/misc/state-county', {
          headers,
          responseType: 'json',
        })
        .pipe(
          tap((response) => {
            let apiResponse = response;
            this.stateCountyDetails = apiResponse.data;
            this.stateCountySubject.next(this.stateCountyDetails);
            this.stateCountyDataLoaded = true;
          }),
          shareReplay(1)
        )
        .subscribe();
    }
    return this.stateCountySubject.asObservable();
  }

  public fetchTimeZones(): Observable<any> {
    if (!this.timeZonesLoaded) {
      const token = localStorage.getItem(this.tokenKey);
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });

      this.http
        .get<any>(environment.apiUrl + '/misc/time-zone', {
          headers,
          responseType: 'json',
        })
        .pipe(
          tap((response) => {
            let apiResponse = response;
            this.timeZones = apiResponse.data;
            this.timeZoneSubject.next(this.timeZones);
            this.timeZonesLoaded = true;
          }),
          shareReplay(1)
        )
        .subscribe();
    }
    return this.timeZoneSubject.asObservable();
  }

  public fetchServiceTypes(): Observable<any> {
    if (!this.serviceTypesLoaded) {
      const token = localStorage.getItem(this.tokenKey);
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });

      this.http
        .get<any>(environment.apiUrl + '/misc/service-type', {
          headers,
          responseType: 'json',
        })
        .pipe(
          tap((response) => {
            let apiResponse = response;
            this.serviceTypes = apiResponse.data;
            this.serviceTypesSubject.next(this.serviceTypes);
            this.serviceTypesLoaded = true;
          }),
          shareReplay(1)
        )
        .subscribe();
    }
    return this.serviceTypesSubject.asObservable();
  }
}
