import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  email: string = '';
  passwordRegx: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#^()_+=~`@$!%*?&])[A-Za-z\d#^()_+=~`@$!%*?&]{8,}$/;

  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.email = localStorage.getItem('email') ?? '';
  }

  protected resetPassword = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(this.passwordRegx),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(this.passwordRegx),
      ]),
    },
    { validators: this.customPasswordMatching.bind(this) }
  );

  public customPasswordMatching(
    control: AbstractControl
  ): ValidationErrors | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    return password === confirmPassword
      ? null
      : { passwordMismatchError: true };
  }

  setPassword() {
    if (this.resetPassword.invalid) {
      this.resetPassword.markAllAsTouched();
      return;
    }
    if (this.resetPassword.valid) {
      this.authService
        .resetPassword(this.email, this.resetPassword.value.password ?? '')
        .subscribe((data) => {
          localStorage.setItem('user', JSON.stringify(data));
          if (data.status === 'SUCCESS') {
            this.appService.setMessage(data.message);
            this.router.navigate(['login']);
          } else {
            this.toastr.error(data.message);
          }
        });
    }
  }

  cancel() {
    this.router.navigate(['login']);
  }
}
