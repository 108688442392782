import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { User } from '../model/user';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import { ProjectService } from '../services/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  selector: 'page-header',
  imports: [
    RouterLink,
    CommonModule,
    NotificationDialogComponent,
    MatBadgeModule,
    FormsModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class Header {
  notificationsActive: boolean = false;
  userProfilePic: String = '';

  user: User = {};
  readNotifications: any = [];
  unreadNotifications: any = [];

  searchTerm: string = '';

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      this.user = <User>JSON.parse(userJson);
      if (this.user && this.user.photo) {
        this.userProfilePic = this.user.photo;
      }
      this.fetchNotifications();
    }
    this.userService.getUser().subscribe((user) => {
      if (user) {
        this.userProfilePic = user.photo;
      }
    });
    this.projectService.showClearSearchButton.subscribe(
      (showButton: boolean) => {
        if (!showButton) {
          this.searchTerm = '';
        }
      }
    );
  }

  fetchNotifications() {
    if (this.user?.id) {
      this.notificationService
        .getVendorNotifications(this.user.id)
        .subscribe((result: any) => {
          if (result.length > 0) {
            this.unreadNotifications = result.filter(
              (notification: any) => notification.status === 'UNREAD'
            );
            this.readNotifications = result.filter(
              (notification: any) => notification.status === 'READ'
            );
          }
        });
    }
  }

  markAllAsRead() {
    this.notificationService
      .markNotificationRead(this.user.id ?? '', this.unreadNotifications)
      .subscribe((result: any) => {
        if (result.length > 0) {
          this.unreadNotifications = result.filter(
            (notification: any) => notification.status === 'UNREAD'
          );
          this.readNotifications = result.filter(
            (notification: any) => notification.status === 'READ'
          );
        }
      });
  }

  markAsRead(notification: any) {
    if (notification.status === 'UNREAD') {
      this.notificationService
        .markNotificationRead(this.user.id ?? '', [notification])
        .subscribe((result: any) => {
          if (result.length > 0) {
            this.unreadNotifications = result.filter(
              (notification: any) => notification.status === 'UNREAD'
            );
            this.readNotifications = result.filter(
              (notification: any) => notification.status === 'READ'
            );
            if (notification.projectPhase) {
              switch (notification.projectPhase) {
                case 'INVITATION': {
                  if (notification.siteId) {
                    this.router.navigate([
                      `invitations/${notification.siteId}`,
                    ]);
                  } else {
                    this.router.navigate(['invitations']);
                  }
                  break;
                }
                case 'PROPOSAL': {
                  if (notification.siteId) {
                    this.router.navigate([`proposals/${notification.siteId}`]);
                  } else {
                    this.router.navigate(['proposals']);
                  }
                  break;
                }
                case 'ACTIVE': {
                  if (notification.siteId) {
                    this.router.navigate([`active/${notification.siteId}`]);
                  } else {
                    this.router.navigate(['active']);
                  }
                  break;
                }
                default: {
                  this.router.navigate(['invitations']);
                  break;
                }
              }
            }
          }
        });
    }
  }

  search() {
    if (this.searchTerm?.length !== 0) {
      this.toastr.info('Searching for address...');
      this.projectService.fetchSearchData(
        this.user.id ?? '',
        this.searchTerm,
        true
      );
      this.projectService.toggleClearSearchButton(true);
    }
  }

  toggleNotificationsModal() {
    this.notificationsActive = !this.notificationsActive;
  }
}
