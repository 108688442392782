import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, RouterLink } from '@angular/router';
import { User } from '../model/user';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule,
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent {
  passwordRegx: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#^()_+=~`@$!%*?&])[A-Za-z\d#^()_+=~`@$!%*?&]{8,}$/;

  user: User = {};
  outOfOfficeStartDate: Date | null = null;
  outOfOfficeEndDate: Date | null = null;
  editOutOfOfficeToggle: boolean = false;
  allNotifiedByEnabled: boolean = false;
  allInvitationNotificationsEnabled: boolean = false;
  allProposalNotifcationsEnabled: boolean = false;
  allActiveProjectNotificationsEnabled: boolean = false;

  protected newPassword = new FormGroup(
    {
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(this.passwordRegx),
      ]),
      confirmNewPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(this.passwordRegx),
      ]),
    },
    { validators: this.customPasswordMatching.bind(this) }
  );

  constructor(
    public router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = <User>JSON.parse(localStorage.getItem('user') ?? '');
    this.outOfOfficeStartDate = this.user.outOfOfficeStartDate
      ? new Date(this.user.outOfOfficeStartDate)
      : null;

    this.outOfOfficeEndDate = this.user.outOfOfficeEndDate
      ? new Date(this.user.outOfOfficeEndDate)
      : null;
    if (Boolean(this.outOfOfficeStartDate && this.outOfOfficeEndDate)) {
      this.editOutOfOfficeToggle = true;
    }

    this.allNotifiedByEnabled =
      (this.user.notifyByInApp && this.user.notifyByEmail) || false;
    this.allInvitationNotificationsEnabled =
      (this.user.newInvitationNotification &&
        this.user.projectUpdateNotification &&
        this.user.cancelledInvitationNotification) ||
      false;
    this.allProposalNotifcationsEnabled =
      (this.user.awardedNotification &&
        this.user.closedNotification &&
        this.user.cancelledProposalNotification) ||
      false;
    this.allActiveProjectNotificationsEnabled =
      (this.user.revisionRequestedNotification &&
        this.user.completedNotification &&
        this.user.cancelledActiveProjectNotification) ||
      false;
  }

  public customPasswordMatching(
    control: AbstractControl
  ): ValidationErrors | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    return password === confirmPassword
      ? null
      : { passwordMismatchError: true };
  }

  editOutOfOffice() {
    this.editOutOfOfficeToggle = !this.editOutOfOfficeToggle;
    if (this.editOutOfOfficeToggle === false) {
      this.outOfOfficeStartDate = null;
      this.outOfOfficeEndDate = null;
      this.handleUpdateUserOutOfOfficeDates();
    }
  }

  saveOutOfOffice() {
    // Check if both start and end dates are provided
    if (this.outOfOfficeStartDate && this.outOfOfficeEndDate) {
      // Validate that the end date is greater than the start date
      if (
        new Date(this.outOfOfficeEndDate) > new Date(this.outOfOfficeStartDate)
      ) {
        // Proceed with saving
        this.handleUpdateUserOutOfOfficeDates();
        // this.editOutOfOfficeToggle = !this.editOutOfOfficeToggle;
      } else {
        // If the end date is not greater than the start date, show an error
        this.toastr.info('End date must be after the start date.');
      }
    } else {
      // If either of the dates is missing
      this.toastr.info('Please select both start and end dates.');
    }
  }

  handleUpdateUserOutOfOfficeDates() {
    let userPayload = this.user;
    userPayload.outOfOfficeStartDate = this.outOfOfficeStartDate;
    userPayload.outOfOfficeEndDate = this.outOfOfficeEndDate;
    this.userService
      .updateUserOutOfOfficeDates(userPayload)
      .subscribe((apiResponse) => {
        if (apiResponse.status === 'FAILURE') {
          this.toastr.error('An error occurred. Please try again.');
        }
        if (apiResponse.code !== 200) {
          this.toastr.error('An error occurred. Please try again.');
        } else if (apiResponse.code === 200) {
          this.toastr.success(apiResponse.message);
          this.user = { ...this.user, ...apiResponse.data };
          localStorage.setItem('user', JSON.stringify(this.user));
        }
      });
  }

  saveNewPassword() {
    if (this.newPassword.invalid) {
      this.newPassword.markAllAsTouched();
      return;
    } else if (this.newPassword.valid) {
      this.authService
        .newPassword(
          this.user.email ?? '',
          this.newPassword.value.currentPassword ?? '',
          this.newPassword.value.newPassword ?? ''
        )
        .subscribe((data) => {
          if (data.status === 'SUCCESS') {
            this.toastr.success(data.message);
            this.newPassword.reset({
              currentPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            });
          } else {
            this.toastr.error(data.message);
          }
        });
    }
  }

  saveNotifiedBy() {
    this.updateUser(this.user);
  }

  saveInvitationNotifications() {
    this.updateUser(this.user);
  }

  saveProposalNotifications() {
    this.updateUser(this.user);
  }

  saveActiveProjectNotifications() {
    this.updateUser(this.user);
  }

  handleSelectAllNotifiedBy() {
    this.user = {
      ...this.user,
      notifyByEmail: this.allNotifiedByEnabled,
      notifyByInApp: this.allNotifiedByEnabled,
    };
  }

  handleNotifiedByChange() {
    this.allNotifiedByEnabled =
      (this.user.notifyByInApp && this.user.notifyByEmail) || false;
  }

  handleSelectAllInvitationNotifications() {
    this.user = {
      ...this.user,
      newInvitationNotification: this.allInvitationNotificationsEnabled,
      projectUpdateNotification: this.allInvitationNotificationsEnabled,
      cancelledInvitationNotification: this.allInvitationNotificationsEnabled,
    };
  }

  handleInvitationNotificationChange() {
    this.allInvitationNotificationsEnabled =
      (this.user.newInvitationNotification &&
        this.user.projectUpdateNotification &&
        this.user.cancelledInvitationNotification) ||
      false;
  }

  handleSelectAllProposalNotifications() {
    this.user = {
      ...this.user,
      awardedNotification: this.allProposalNotifcationsEnabled,
      closedNotification: this.allProposalNotifcationsEnabled,
      cancelledProposalNotification: this.allProposalNotifcationsEnabled,
    };
  }

  handleProposalNotificationChange() {
    this.allProposalNotifcationsEnabled =
      (this.user.awardedNotification &&
        this.user.closedNotification &&
        this.user.cancelledProposalNotification) ||
      false;
  }

  handleSelectAllActiveProjectNotifications() {
    this.user = {
      ...this.user,
      revisionRequestedNotification: this.allActiveProjectNotificationsEnabled,
      completedNotification: this.allActiveProjectNotificationsEnabled,
      cancelledActiveProjectNotification:
        this.allActiveProjectNotificationsEnabled,
    };
  }

  handleActiveProjectNotificationChange() {
    this.allActiveProjectNotificationsEnabled =
      (this.user.revisionRequestedNotification &&
        this.user.completedNotification &&
        this.user.cancelledActiveProjectNotification) ||
      false;
  }

  goBack() {
    this.router.navigate(['invitations']);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
  }

  private updateUser(user: User) {
    this.userService.updateUser(user).subscribe((response: any) => {
      const apiResponse = response;
      if (apiResponse.status === 'FAILURE') {
        if (apiResponse.message) {
          this.toastr.error(apiResponse.message);
        } else {
          this.toastr.error('An error occurred. Please try again.');
        }
      } else {
        const updatedUser = response.data;
        if (updatedUser) {
          this.user = { ...this.user, ...updatedUser };
          localStorage.setItem('user', JSON.stringify(this.user));
          this.toastr.success(apiResponse.message);
        } else {
          this.toastr.error('An error occurred. Please try again.');
        }
      }
    });
  }
}
