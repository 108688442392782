import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notifications: any;
  private tokenKey = 'token';

  constructor(private http: HttpClient) {}

  public getVendorNotifications(userId: string): Observable<any> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiUrl + `/notification/user-notifications?userId=${userId}`,
      { headers, responseType: 'json' }
    );
  }

  public markNotificationRead(
    userId: string,
    notificationList: any
  ): Observable<any> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.put(
      environment.apiUrl +
        `/notification/mark-notification-read?userId=${userId}`,
      JSON.stringify(notificationList),
      {
        headers,
        responseType: 'json',
      }
    );
  }
}
