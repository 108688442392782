import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoTableItem } from '../info-table-item/info-table-item.component';
import { CommonModule } from '@angular/common';
import { ProposalService } from '../services/proposal.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../services/modal.service';

@Component({
  standalone: true,
  selector: 'info-table',
  imports: [InfoTableItem, CommonModule],
  templateUrl: './info-table.component.html',
  styleUrl: './info-table.component.scss',
})
export class InfoTable {
  @Input() projectId: string = '';
  @Input() data: any = ''; //site details
  @Input() type: string = '';
  @Input() expanded: boolean = true;
  @Input() index: number = 0;

  @Output() handleSitesUpdate = new EventEmitter<any>();
  // need to handle when edit function is triggered (multiple fields)
  @Output() handleEditBidAndTurnTime = new EventEmitter<any>();
  @Output() handleDeclineInvitation = new EventEmitter<any>();

  statusSort: boolean = false;
  siteSort: boolean = false;
  addressSort: boolean = false;
  acreageSort: boolean = false;
  landUseSort: boolean = false;
  siteTypeSort: boolean = false;
  dueDateSort: boolean = false;
  actionsSort: boolean = false;

  gridLayout: string =
    'var(--status) var(--site) var(--acreage) var(--landUse) var(--siteType) var(--dueDate) var(--serviceInfo) var(--actions)';
  moreOpen: boolean = false;
  showStatus: boolean = true;
  showSite: boolean = true;
  showAcreage: boolean = true;
  showLandUse: boolean = true;
  showSiteType: boolean = true;
  showDueDate: boolean = true;
  showTableA: boolean = true;
  showActions: boolean = true;
  tableA: string = '';
  tableAItemName: string = '';
  tableAItemValue: string = '';
  item20: string = '';
  address: any = { streetAddress: '', cityStateZip: '' };
  siteType = '';
  declineSuccessActive: boolean = false;

  constructor(
    private proposalService: ProposalService,
    private toastr: ToastrService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.siteSort = this.filterToggle(this.siteSort, 'site');
    this.adjustTable();
  }

  filterToggle(input: boolean, sortBy: string = '') {
    this.data.sort((a: any, b: any) => {
      let res = 0;
      if (sortBy !== 'dueDate') {
        if (a[sortBy] < b[sortBy]) res = -1;
        if (a[sortBy] > b[sortBy]) res = 1;
      } else {
        let dateA = new Date(a[sortBy]);
        let dateB = new Date(b[sortBy]);
        if (dateA < dateB) res = -1;
        if (dateA > dateB) res = 1;
      }
      if (input) res = -res;
      return res;
    });
    return !input;
  }

  more() {
    this.moreOpen = !this.moreOpen;
  }

  toggleColumn(input: boolean) {
    return !input;
  }

  toggleOpenDeclineSuccessModal(siteDetail: any) {
    this.modalService.toggleOpenDeclineSuccessModal(siteDetail);
  }

  toggleCloseDeclineSuccessModal() {
    this.modalService.toggleCloseDeclineSuccessModal();
  }

  adjustTable() {
    this.gridLayout = '';
    if (this.showStatus) this.gridLayout += 'var(--status) ';
    if (this.showSite) this.gridLayout += 'var(--site) ';
    if (this.showAcreage) this.gridLayout += 'var(--acreage) ';
    if (this.showLandUse) this.gridLayout += 'var(--land-use) ';
    if (this.showSiteType) this.gridLayout += 'var(--service-type) ';
    //if (this.showDueDate) this.gridLayout += 'var(--due-date) ';commented bases on Rainier comments https://escreenlogic.atlassian.net/browse/CON-75
    if (this.showTableA) this.gridLayout += 'var(--table-a) ';
    this.gridLayout += 'var(--documents) ';
    if (this.showActions) this.gridLayout += 'var(--action) ';
    this.gridLayout += 'var(--edit) ';
    this.gridLayout += 'var(--view) ';
    this.gridLayout = this.gridLayout.trim();
  }

  handleSiteUpdate(event: { siteId: string; fieldName: String; value: any }) {
    const updatedSites = this.data.map((siteDetails: any) => {
      if (siteDetails.site.id === event.siteId) {
        let proposal = siteDetails.proposal;
        if (event.fieldName === 'turnTime') {
          proposal = { ...proposal, proposedTurnTime: event.value };
        } else if (event.fieldName === 'bidAmount') {
          proposal = { ...proposal, bid: event.value };
        } else if (event.fieldName === 'bidNotes') {
          proposal = { ...proposal, bidNotes: event.value };
        }
        this.proposalService.saveProposal(proposal);
        return { ...siteDetails, proposal: proposal };
      } else {
        return siteDetails;
      }
    });
    this.data = updatedSites;
    this.handleSitesUpdate.emit({ sites: updatedSites });
  }

  handleReviewSubmitted(event: { siteId: string; updatedProposal: any }) {
    const updatedSites = this.data.map((siteDetails: any) => {
      if (siteDetails.site.id === event.siteId) {
        return { ...siteDetails, proposal: event.updatedProposal };
      } else {
        return siteDetails;
      }
    });
    this.data = updatedSites;
    this.handleSitesUpdate.emit({ sites: updatedSites });
  }

  handleEdit(event: { siteId: string; bid: number; turnTime: number }) {
    const updatedSites = this.data.map((siteDetail: any) => {
      if (siteDetail.site.id === event.siteId) {
        let proposal = siteDetail.proposal;
        proposal = {
          ...proposal,
          bid: event.bid,
          proposedTurnTime: event.turnTime,
        };
        this.proposalService.editBid(proposal);
        return { ...siteDetail, proposal: proposal };
      } else {
        return siteDetail;
      }
    });
    this.data = updatedSites;
    this.handleSitesUpdate.emit({ sites: updatedSites });
  }

  handleDecline(event: { proposal: any }) {
    this.proposalService
      .declineInvitation(event.proposal)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProposal = apiResponse.data;
          if (updatedProposal) {
            const updatedSites = this.data.map((siteDetail: any) => {
              if (siteDetail.site.id === updatedProposal.siteId) {
                this.toggleOpenDeclineSuccessModal(siteDetail);
                return { ...siteDetail, proposal: updatedProposal };
              } else {
                return siteDetail;
              }
            });
            this.handleSitesUpdate.emit({ sites: updatedSites });
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  handleDocumentUpdate(event: { updatedSiteDetail: any }) {
    const updatedSites = this.data.map((siteDetail: any) => {
      if (siteDetail.site.id === event.updatedSiteDetail.site.id) {
        return event.updatedSiteDetail;
      } else {
        return siteDetail;
      }
    });
    this.handleSitesUpdate.emit({ sites: updatedSites });
  }
}
