<div
  class="info-table info-table--{{ type }}"
  [style.grid-template-columns]="gridLayout"
>
  <ng-container *ngIf="expanded">
    <div class="info-table-header">
      <button
        *ngIf="showStatus"
        (click)="statusSort = filterToggle(statusSort, 'status')"
      >
        Status<span class="material-icons">filter_list</span>
      </button>
      <button
        *ngIf="showSite"
        (click)="siteSort = filterToggle(siteSort, 'site')"
      >
        Site<span class="material-icons">filter_list</span>
      </button>
      <button
        *ngIf="showAcreage"
        (click)="acreageSort = filterToggle(acreageSort, 'acreage')"
      >
        Acreage<span class="material-icons">filter_list</span>
      </button>
      <button
        class="info-table-header__service-type"
        *ngIf="showSiteType"
        (click)="siteTypeSort = filterToggle(siteTypeSort, 'siteType')"
      >
        Service Type<span class="material-icons">filter_list</span>
      </button>
      <button
        class="info-table-header__land-use"
        *ngIf="showLandUse"
        (click)="landUseSort = filterToggle(landUseSort, 'landUse')"
      >
        Land Use<span class="material-icons">filter_list</span>
      </button>
      <button class="info-table-header__service-info" *ngIf="showTableA">
        Table A
      </button>
      <!-- <button *ngIf="showDueDate" (click)="dueDateSort = filterToggle(dueDateSort, 'dueDate')">Due Date<span
          class="material-icons">filter_list</span></button> commented bases on Rainier comments https://escreenlogic.atlassian.net/browse/CON-75 -->
      <button class="info-table-header__docs-info">Docs & Info</button>
      <button
        *ngIf="showActions"
        class="info-table-header__action"
        (click)="actionsSort = filterToggle(actionsSort, 'actions')"
      >
        Action<span class="material-icons">filter_list</span>
      </button>
      <div class="info-table-header__more">
        <button (click)="more()">
          <span class="material-icons">more_vert</span>
        </button>
        <div *ngIf="moreOpen" class="info-table-header__more__menu">
          <div
            class="info-table-header__more__menu__item"
            (click)="showStatus = toggleColumn(showStatus); adjustTable()"
          >
            <span *ngIf="showStatus" class="material-icons">check</span>
            <p>Status</p>
          </div>
          <div
            class="info-table-header__more__menu__item"
            (click)="showSite = toggleColumn(showSite); adjustTable()"
          >
            <span *ngIf="showSite" class="material-icons">check</span>
            <p>Location</p>
          </div>
          <div
            class="info-table-header__more__menu__item"
            (click)="showAcreage = toggleColumn(showAcreage); adjustTable()"
          >
            <span *ngIf="showAcreage" class="material-icons">check</span>
            <p>Acreage</p>
          </div>
          <div
            class="info-table-header__more__menu__item"
            (click)="showSiteType = toggleColumn(showSiteType); adjustTable()"
          >
            <span *ngIf="showSiteType" class="material-icons">check</span>
            <p>Service Type</p>
          </div>
          <div
            class="info-table-header__more__menu__item"
            (click)="showLandUse = toggleColumn(showLandUse); adjustTable()"
          >
            <span *ngIf="showLandUse" class="material-icons">check</span>
            <p>Land Use</p>
          </div>
          <!-- future implmentation: Phase I and PCA-->
          <!-- <div
            class="info-table-header__more__menu__item"
            (click)="showDueDate = toggleColumn(showDueDate); adjustTable()"
          >
            <span *ngIf="showDueDate" class="material-icons">check</span>
            <p>Proposal Due</p>
          </div> -->
          <div
            class="info-table-header__more__menu__item"
            (click)="showTableA = toggleColumn(showTableA); adjustTable()"
          >
            <span *ngIf="showTableA" class="material-icons">check</span>
            <p>Table A</p>
          </div>
          <div
            class="info-table-header__more__menu__item"
            (click)="showActions = toggleColumn(showActions); adjustTable()"
          >
            <span *ngIf="showActions" class="material-icons">check</span>
            <p>Action</p>
          </div>
        </div>
      </div>
    </div>
    <project-overview-table-row
      *ngFor="let item of data; let i = index"
      [id]="item.site.id"
      [rowIndex]="i"
      [data]="item"
      [status]="item.proposal.status"
      [site]="item.site.siteNumber"
      [address]="address"
      [acreage]="item.site.totalAcreage"
      [landUse]="item.site.landUse"
      [siteType]="item.site.siteType"
      [tableA]="tableA"
      [item20]="item20"
      [dueDate]="item.site.bidDueDate"
      [turnTime]="item.proposal.proposedTurnTime"
      [bidAmount]="item.proposal.bid"
      [reviewStatus]="item.reviewStatus"
      [type]="type"
      [showStatus]="showStatus"
      [showSite]="showSite"
      [showAcreage]="showAcreage"
      [showLandUse]="showLandUse"
      [showTableA]="showTableA"
      [showSiteType]="showSiteType"
      [showDueDate]="showDueDate"
      [showActions]="showActions"
      (handleSiteUpdate)="handleSiteUpdate($event)"
      (handleEditBidAndTurnTime)="handleEdit($event)"
      (handleDeclineInvitation)="handleDecline($event)"
      (handleReviewSubmitted)="handleReviewSubmitted($event)"
      (handleUpdateDocuments)="handleDocumentUpdate($event)"
      [bidNotes]="item.proposal.bidNotes"
    >
    </project-overview-table-row>
  </ng-container>
</div>
