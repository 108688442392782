<div class="info-group">
  <project-overview-header
    [data]="data"
    [expanded]="expanded"
    [type]="type"
    [enableSubmitAllBids]="enableSubmitAllBids"
    [numberOfBids]="numberOfBids"
    [showAtp]="showAtp"
    (toggled)="changeOpenState($event)"
  ></project-overview-header>
  <project-overview-table
    [projectId]="data?.projectId"
    [data]="data?.sites"
    [type]="type"
    [expanded]="expanded"
    [index]="index"
    (handleSitesUpdate)="handleSitesUpdate($event)"
  ></project-overview-table>
</div>
