import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectReviewCompletedComponent } from '../project-review-completed/project-review-completed.component';
import { ReviewOrderSubmittedDialogComponent } from '../review-order-submitted-dialog/review-order-submitted-dialog.component';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'submit-for-review-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ProjectReviewCompletedComponent,
    ReviewOrderSubmittedDialogComponent,
  ],
  templateUrl: './submit-for-review-dialog.component.html',
  styleUrl: './submit-for-review-dialog.component.scss',
})
export class SubmitForReviewDialogComponent {
  @Input() active: boolean = false;
  @Input() siteId: string = '';
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() submitReview = new EventEmitter<String>();
  @Output() submitRevision = new EventEmitter<String>();

  @Input() documents: any = '';
  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() status: string = '';
  deliverableDocuments: any = [];

  reviewCompletedActive: boolean = false;
  isReviewOrderSubmitted: boolean = false;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    if (this.status === 'ACTIVE') {
      this.deliverableDocuments = this.documents.filter(
        (document: { type: string }) => document.type === 'DELIVERABLE'
      );
    } else if (this.status === 'REVISION_REQUESTED') {
      this.deliverableDocuments = this.documents.filter(
        (document: { type: string }) => document.type === 'REVISION'
      );
    }
    this.modalService.isSubmitDeliverableModalOpen.subscribe((open) => {
      if (this.siteId === open.siteId) {
        this.isReviewOrderSubmitted = open.isOpen;
      }
    });
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  toggleReviewCompletedActiveModal() {
    this.reviewCompletedActive != this.reviewCompletedActive;
  }

  toggleReviewCompletedOpen() {
    this.modalService.toogleOpenSubmitDeliverableModal(this.siteId);
  }

  toggleReviewCompletedClosed() {
    this.modalService.toogleCloseSubmitDeliverableModal();
  }

  submit() {
    if (this.status === 'ACTIVE') {
      this.submitReview.emit();
    } else if (this.status === 'REVISION_REQUESTED') {
      this.submitRevision.emit();
    }
    this.closeDialog();
    this.toggleReviewCompletedOpen();
  }
}
