import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProjectOverviewTableComponent } from '../project-overview-table/project-overview-table.component';
import { ProjectService } from '../../services/project.service';
import { ProjectOverviewHeaderComponent } from '../project-overview-header/project-overview-header.component';

@Component({
  selector: 'project-overview-container',
  standalone: true,
  imports: [
    CommonModule,
    ProjectOverviewTableComponent,
    ProjectOverviewHeaderComponent,
  ],
  templateUrl: './project-overview-container.component.html',
  styleUrl: './project-overview-container.component.scss',
})
export class ProjectOverviewContainerComponent {
  @Input() data: any = [];
  @Input() type: string = '';
  @Input() expanded: boolean = true;
  @Input() index: number = 0;
  @Input() enableSubmitAllBids: boolean = false;
  @Input() numberOfBids: number = 0;
  @Input() showAtp: boolean = false;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    const expanded = localStorage.getItem(this.data.projectId);
    if (this.index === 0 || expanded) {
      this.expanded = true;
    }
  }

  changeOpenState(exapnded: any) {
    this.expanded = exapnded;
  }

  handleSitesUpdate(event: { sites: any }) {
    this.data = { ...this.data, sites: event.sites };
    this.projectService.updateProjectDataSubject(this.data);
  }
}
