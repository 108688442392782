import { Routes } from '@angular/router';
import { ProposalsPage } from './proposals-page/proposals-page.component';
import { ActivePage } from './active-page/active-page.component';
import { InvitationsPage } from './invitations-page/invitations-page.component';
import { ProposalDetails } from './proposal-details/proposal-details.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { CreateAccountThanksComponent } from './create-account-thanks/create-account-thanks.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './helpers/auth.guard';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SettingsComponent } from './settings/settings.component';
import { ProjectHistoryPageComponent } from './project-history/project-history-page/project-history-page.component';
import { ProjectOverviewPageComponent } from './project-overview/project-overview-page/project-overview-page.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'invitations', component: InvitationsPage, canActivate: [AuthGuard] },
  { path: 'proposals', component: ProposalsPage, canActivate: [AuthGuard] },
  {
    path: 'proposals/:id',
    component: ProposalDetails,
    data: { type: 'proposal' },
    canActivate: [AuthGuard],
  },
  {
    path: 'invitations/:id',
    component: ProposalDetails,
    data: { type: 'invitation' },
    canActivate: [AuthGuard],
  },
  {
    path: 'active/:id',
    component: ProposalDetails,
    data: { type: 'active' },
    canActivate: [AuthGuard],
  },
  { path: 'active', component: ActivePage, canActivate: [AuthGuard] },
  { path: 'createaccount', component: CreateAccountComponent },
  { path: 'thanks', component: CreateAccountThanksComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  {
    path: 'manageaccount',
    component: ManageAccountComponent,
    canActivate: [AuthGuard],
  },
  { path: 'contact', component: ContactUsComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  {
    path: 'project-overview/:id',
    component: ProjectOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project-overview-proposal/:id',
    component: ProposalDetails,
    data: { type: 'project-overview' },
    canActivate: [AuthGuard],
  },
  {
    path: 'project-history',
    component: ProjectHistoryPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project-history/:id',
    component: ProposalDetails,
    data: { type: 'project-history' },
    canActivate: [AuthGuard],
  },
];
