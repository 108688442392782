<div class="menu" *ngIf="active">
  <div class="notifications-title">Notifications</div>
  <button class="documents-dialog__content__close" (click)="closeDialog()">
    <span class="material-icons">close</span>
  </button>
  <div class="menu-header">
    <div class="proposal-details__header__tabs">
      <button
        (click)="toggleTab('unread')"
        class="tab-btn"
        [class.active]="activeTab == 'unread'"
      >
        Unread
      </button>
      <button
        (click)="toggleTab('read')"
        class="tab-btn"
        [class.active]="activeTab == 'read'"
      >
        Read
      </button>
      <button
        [disabled]="unreadNotifications?.length === 0"
        (click)="markAllNotificationsRead()"
        class="tab-btn"
        [class.active]="activeTab == 'markallread'"
      >
        Mark All As Read
      </button>
    </div>
  </div>
  <div class="menu-body">
    <div class=" " *ngIf="activeTab == 'unread'">
      <div *ngIf="unreadNotifications?.length === 0; else unread">
        <p class="placeholder">No unread notifications.</p>
      </div>
      <ng-template #unread>
        <div
          class="tab-content"
          *ngFor="let notification of unreadNotifications"
        >
          <div
            class="info-navigation__left__button info-navigation__left__button--proposal"
            (click)="markNotificationRead(notification)"
          >
            <mat-icon *ngIf="notification.type === 'ERROR'" style="color: red"
              >error</mat-icon
            >
            <p>
              <span class="subject">{{ notification.subject }}</span>
              <br />
              <span class="body">{{ notification.body }}</span>
              <br />
              <span class="date">{{
                notification.createdOn | date : "MM-dd-yyyy hh:mm a" : locale
              }}</span>
            </p>
          </div>
        </div>
      </ng-template>
    </div>
    <div class=" " *ngIf="activeTab == 'read'">
      <div *ngIf="readNotifications?.length === 0; else read">
        <p class="placeholder">No previously read notifications.</p>
      </div>
      <ng-template #read>
        <div class="tab-content" *ngFor="let notification of readNotifications">
          <div
            class="info-navigation__left__button info-navigation__left__button--proposal"
          >
            <mat-icon *ngIf="notification.type === 'ERROR'" style="color: red"
              >error</mat-icon
            >
            <p>
              <span class="subject">{{ notification.subject }}</span
              ><br />
              <span class="body">{{ notification.body }}</span>
              <br />
              <span class="date"
                >Received at
                {{
                  notification.createdOn | date : "MM-dd-yyyy hh:mm a" : locale
                }}</span
              >
            </p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
