<div class="container">
  <div class="left-navigation">
    <div class="back-btn" (click)="goBack()">
      <span class="material-icons">arrow_back_ios</span>
      <a class="back-btn">Back</a>
    </div>
    <hr />
    <div class="my-account">My Account</div>
    <div class="left-nav" [routerLink]="'/manageaccount'">
      <span class="material-icons">person</span>
      <a class="left-links">My Profile</a>
    </div>
    <div class="left-nav" [routerLink]="'/settings'">
      <span class="material-icons">settings</span>
      <a class="left-links">Account Settings</a>
    </div>
    <hr />
    <div
      class="left-nav"
      [routerLink]="'/contact'"
      routerLinkActive="router-link-active"
    >
      <span class="material-icons">info</span>
      <a class="left-links">Contact Us</a>
    </div>
    <div class="left-nav">
      <span class="material-icons">logout</span>
      <a (click)="logout()" class="left-links">Logout</a>
    </div>
  </div>
  <div class="content-area">
    <div class="proposal-details__header">
      <div class="proposal-details__header__info mainheader">
        <h1>
          <span class="material-icons">person</span>
          {{ user.firstName + " " + user.lastName }}
        </h1>
        <div class="profile-picture">
          <div *ngIf="user.photo; else noPhoto">
            <label for="profile-pic-upload">
              <img [src]="user.photo" alt="Profile Picture" />
            </label>
          </div>
          <ng-template #noPhoto>
            <label for="profile-pic-upload">
              <span class="material-icons profile-pic">person</span></label
            >
          </ng-template>
          <label for="profile-pic-upload" class="profile-picture_text">
            Edit
          </label>
          <input
            id="profile-pic-upload"
            class="profile-pic-upload"
            type="file"
            accept=".png, .jpg"
            (change)="uploadProfilePic($event)"
          />
        </div>
      </div>
      <div class="proposal-details__header__tabs">
        <button
          (click)="toggleTab('profile')"
          [class.active]="activeTab == 'profile'"
        >
          Profile
        </button>
        <button
          (click)="toggleTab('organization')"
          [class.active]="activeTab == 'organization'"
        >
          Organization
        </button>
        <button
          (click)="toggleTab('servicezones')"
          [class.active]="activeTab == 'servicezones'"
        >
          Services & Zones
        </button>

        <button
          (click)="toggleTab('documents')"
          [class.active]="activeTab == 'documents'"
        >
          Documents
        </button>
      </div>
    </div>
    <div class="profile" *ngIf="activeTab == 'profile'">
      <div class="tabcontent">
        <table class="detail-table">
          <tr>
            <td class="col1">
              <form [formGroup]="nameUpdate">
                <div class="tabcard">
                  <div class="tab-header">Name</div>
                  <div
                    class="btndiv"
                    *ngIf="!editNameToggle"
                    (click)="editName()"
                  >
                    <span class="material-icons edit-details"
                      >border_color</span
                    >
                    Edit
                  </div>
                  <div
                    class="btn-save"
                    *ngIf="editNameToggle"
                    type="submit"
                    (click)="saveName()"
                  >
                    <span class="material-icons">save</span> Save
                  </div>
                </div>
                <table class="detail-table">
                  <tr>
                    <td class="col1">
                      <p class="small-txt">
                        First Name
                        <span *ngIf="editNameToggle" class="req">*</span>
                      </p>
                      <p *ngIf="!editNameToggle" class="lbl-value">
                        {{ user.firstName }}
                      </p>
                      <input
                        *ngIf="editNameToggle"
                        id="firstName"
                        type="text"
                        formControlName="firstName"
                        class="input"
                      />
                    </td>
                    <td class="col2"></td>
                    <td class="col3">
                      <p class="small-txt">
                        Last Name<span *ngIf="editNameToggle" class="req"
                          >*</span
                        >
                      </p>
                      <p *ngIf="!editNameToggle" class="lbl-value">
                        {{ user.lastName }}
                      </p>
                      <input
                        *ngIf="editNameToggle"
                        id="lastName"
                        type="text"
                        formControlName="lastName"
                        class="input"
                      />
                    </td>
                  </tr>
                </table>
              </form>
            </td>
            <td class="col2"></td>
            <td class="col3">
              <form [formGroup]="emailUpdate">
                <div class="tabcard">
                  <div class="tab-header">Email</div>
                  <div
                    class="btndiv"
                    *ngIf="!editEmailToggle"
                    (click)="editEmail()"
                  >
                    <span class="material-icons edit-details"
                      >border_color</span
                    >
                    Edit
                  </div>
                  <div
                    class="btn-save"
                    *ngIf="editEmailToggle"
                    (click)="saveEmail()"
                  >
                    <span class="material-icons">save</span> Save
                  </div>
                </div>
                <table class="detail-table">
                  <tr>
                    <td>
                      <p class="small-txt">
                        Login Email
                        <span *ngIf="editEmailToggle" class="req">*</span>
                      </p>
                      <p *ngIf="!editEmailToggle" class="lbl-value">
                        {{ user.email }}
                      </p>
                      <input
                        *ngIf="editEmailToggle"
                        id="loginEmail"
                        type="text"
                        formControlName="email"
                        class="input"
                      />
                    </td>
                  </tr>
                </table>
              </form>
            </td>
          </tr>
          <tr>
            <td class="col1">
              <div class="tabcard">
                <div class="tab-header">Phone</div>
                <div
                  class="btndiv"
                  *ngIf="!editPhoneToggle"
                  (click)="editPhone()"
                >
                  <span class="material-icons edit-details">border_color</span>
                  Edit
                </div>
                <div
                  class="btn-save"
                  *ngIf="editPhoneToggle"
                  (click)="savePhone()"
                >
                  <span class="material-icons">save</span> Save
                </div>
              </div>
              <table class="detail-table">
                <tr>
                  <td class="col1">
                    <p class="small-txt">Phone Number</p>
                    <p *ngIf="!editPhoneToggle" class="lbl-value">
                      {{ user.phone }}
                    </p>
                    <input
                      *ngIf="editPhoneToggle"
                      id="phone"
                      type="text"
                      [(ngModel)]="phone"
                      class="input"
                    />
                  </td>
                  <td class="col2"></td>
                  <td class="col3">
                    <p class="small-txt">Phone OS</p>
                    <p *ngIf="!editPhoneToggle" class="lbl-value">
                      {{ user.phoneOs }}
                    </p>
                    <input
                      *ngIf="editPhoneToggle"
                      id="phoneOS"
                      type="text"
                      [(ngModel)]="phoneOs"
                      class="input"
                    />
                  </td>
                </tr>
              </table>
            </td>
            <td class="col2"></td>
            <td class="col3"></td>
          </tr>
        </table>
        <div class="tabcard">
          <div class="tab-header">Address</div>
          <div
            class="btndiv"
            *ngIf="!editAddressToggle"
            (click)="editAddress()"
          >
            <span class="material-icons edit-details">border_color</span>
            Edit
          </div>
          <div
            class="btn-save"
            *ngIf="editAddressToggle"
            (click)="saveAddress()"
          >
            <span class="material-icons">save</span> Save
          </div>
        </div>
        <table class="address-detail-table">
          <tr>
            <td>
              <p class="small-txt">Street Address</p>
              <p *ngIf="!editAddressToggle" class="lbl-value">
                {{ user.location?.street }}
              </p>
              <input
                *ngIf="editAddressToggle"
                id="street"
                type="text"
                [(ngModel)]="streetAddress"
                class="input"
              />
            </td>
            <td>
              <p class="small-txt">City</p>
              <p *ngIf="!editAddressToggle" class="lbl-value">
                {{ user.location?.city }}
              </p>
              <input
                *ngIf="editAddressToggle"
                id="city"
                type="text"
                [(ngModel)]="city"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p class="small-txt">State</p>
              <p
                *ngIf="!editAddressToggle; else editUserState"
                class="lbl-value"
              >
                {{ decodeState(user.location?.state) }}
              </p>
              <ng-template #editUserState
                ><select class="form-select" [(ngModel)]="state">
                  <option
                    *ngFor="let stateCountyDetail of stateCountyDetails"
                    [value]="stateCountyDetail.state"
                  >
                    {{ stateCountyDetail.name }}
                  </option>
                </select>
              </ng-template>
            </td>
            <td>
              <p class="small-txt">Zip Code</p>
              <p *ngIf="!editAddressToggle" class="lbl-value">
                {{ user.location?.zipcode }}
              </p>
              <input
                *ngIf="editAddressToggle"
                id="zipcode"
                type="text"
                [(ngModel)]="zipcode"
                class="input"
              />
            </td>
          </tr>
        </table>
        <div class="tabcard">
          <div class="tab-header">CC Info</div>
          <div class="btndiv" *ngIf="!editCcInfoToggle" (click)="editCCInfo()">
            <span class="material-icons edit-details">border_color</span>
            Edit
          </div>
          <div class="btn-save" *ngIf="editCcInfoToggle" (click)="saveCCInfo()">
            <span class="material-icons">save</span> Save
          </div>
        </div>
        <table class="address-detail-table">
          <tr>
            <td>
              <p class="small-txt">CC Name 1</p>
              <p *ngIf="!editCcInfoToggle" class="lbl-value">
                {{ user.ccName1 }}
              </p>
              <input
                *ngIf="editCcInfoToggle"
                id="phone"
                type="text"
                [(ngModel)]="ccName1"
                class="input"
              />
            </td>
            <td>
              <p class="small-txt">CC Name 2</p>
              <p *ngIf="!editCcInfoToggle" class="lbl-value">
                {{ user.ccName2 }}
              </p>
              <input
                *ngIf="editCcInfoToggle"
                id="phone"
                type="text"
                [(ngModel)]="ccName2"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p class="small-txt">CC Email 1</p>
              <p *ngIf="!editCcInfoToggle" class="lbl-value">
                {{ user.ccEmail1 }}
              </p>
              <input
                *ngIf="editCcInfoToggle"
                id="phone"
                type="text"
                [(ngModel)]="ccEmail1"
                class="input"
              />
            </td>
            <td>
              <p class="small-txt">CC Email 2</p>
              <p *ngIf="!editCcInfoToggle" class="lbl-value">
                {{ user.ccEmail2 }}
              </p>
              <input
                *ngIf="editCcInfoToggle"
                id="phone"
                type="text"
                [(ngModel)]="ccEmail2"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p class="small-txt">CC Phone Number 1</p>
              <p *ngIf="!editCcInfoToggle" class="lbl-value">
                {{ user.ccPhone1 }}
              </p>
              <input
                *ngIf="editCcInfoToggle"
                id="phone"
                type="text"
                [(ngModel)]="ccPhone1"
                class="input"
              />
            </td>
            <td>
              <p class="small-txt">CC Phone Number 2</p>
              <p *ngIf="!editCcInfoToggle" class="lbl-value">
                {{ user.ccPhone2 }}
              </p>
              <input
                *ngIf="editCcInfoToggle"
                id="phone"
                type="text"
                [(ngModel)]="ccPhone2"
                class="input"
              />
            </td>
          </tr>
        </table>
        <div class="tabcard">
          <div class="tab-header">Additional Information</div>
          <div
            class="btndiv"
            *ngIf="!editProfileAdditionalInfoToggle"
            (click)="editProfileAddtionalInfo()"
          >
            <span class="material-icons edit-details">border_color</span>
            Edit
          </div>
          <div
            class="btn-save"
            *ngIf="editProfileAdditionalInfoToggle"
            (click)="saveProfileAdditionalInfo()"
          >
            <span class="material-icons">save</span> Save
          </div>
        </div>
        <table class="address-detail-table">
          <tr>
            <td>
              <p class="small-txt">Bio</p>
              <p *ngIf="!editProfileAdditionalInfoToggle" class="lbl-value">
                {{ user.bio }}
              </p>
              <textarea
                *ngIf="editProfileAdditionalInfoToggle"
                id="bio"
                name="bio"
                class="text-area"
                rows="7"
                [(ngModel)]="bio"
              ></textarea>
            </td>
            <td>
              <p class="small-txt">Time Zone</p>
              <p
                *ngIf="!editProfileAdditionalInfoToggle; else editTimeZone"
                class="lbl-value"
              >
                {{ timezone?.text }}
              </p>
              <ng-template #editTimeZone
                ><select class="form-select" [(ngModel)]="timezone">
                  <option
                    *ngFor="let timeZone of timeZones"
                    [ngValue]="timeZone"
                  >
                    {{ timeZone.text }}
                  </option>
                </select>
              </ng-template>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="organization" *ngIf="activeTab == 'organization'">
      <div class="tabcontent">
        <div class="tabcard">
          <div class="tab-header">organization</div>
          <div
            class="btndiv"
            *ngIf="!editOrgInfoToggle"
            (click)="editOrgInfo()"
          >
            <span class="material-icons edit-details">border_color</span>
            Edit
          </div>
          <div
            class="btn-save"
            *ngIf="editOrgInfoToggle"
            (click)="saveOrgInfo()"
          >
            <span class="material-icons">save</span> Save
          </div>
        </div>
        <table class="address-detail-table">
          <tr>
            <td>
              <p class="small-txt">Organization Name</p>
              <p *ngIf="!editOrgInfoToggle" class="lbl-value">
                {{ user.organization?.name }}
              </p>
              <input
                *ngIf="editOrgInfoToggle"
                id="orgName"
                type="text"
                [(ngModel)]="orgName"
                class="input"
              />
            </td>
            <td>
              <p class="small-txt">Organization Email</p>
              <p *ngIf="!editOrgInfoToggle" class="lbl-value">
                {{ user.organization?.email }}
              </p>
              <input
                *ngIf="editOrgInfoToggle"
                id="orgEmail"
                type="text"
                [(ngModel)]="orgEmail"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p class="small-txt">Organization Phone Number</p>
              <p *ngIf="!editOrgInfoToggle" class="lbl-value">
                {{ user.organization?.phone }}
              </p>
              <input
                *ngIf="editOrgInfoToggle"
                id="orgPhone"
                type="text"
                [(ngModel)]="orgPhone"
                class="input"
              />
            </td>
            <td></td>
          </tr>
        </table>
        <div class="tabcard">
          <div class="tab-header">Address</div>
          <div
            class="btndiv"
            *ngIf="!editOrgAddressToggle"
            (click)="editOrgAddress()"
          >
            <span class="material-icons edit-details">border_color</span>Edit
          </div>
          <div
            class="btn-save"
            *ngIf="editOrgAddressToggle"
            (click)="saveOrgAddress()"
          >
            <span class="material-icons">save</span> Save
          </div>
        </div>
        <table class="address-detail-table">
          <tr>
            <td>
              <p class="small-txt">Street Address</p>
              <p *ngIf="!editOrgAddressToggle" class="lbl-value">
                {{ user.organization?.location?.street }}
              </p>
              <input
                *ngIf="editOrgAddressToggle"
                id="street"
                type="text"
                [(ngModel)]="orgStreetAddress"
                class="input"
              />
            </td>
            <td>
              <p class="small-txt">City</p>
              <p *ngIf="!editOrgAddressToggle" class="lbl-value">
                {{ user.organization?.location?.city }}
              </p>
              <input
                *ngIf="editOrgAddressToggle"
                id="city"
                type="text"
                [(ngModel)]="orgCity"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p class="small-txt">State</p>
              <p
                *ngIf="!editOrgAddressToggle; else editUserState"
                class="lbl-value"
              >
                {{ decodeState(user.organization?.location?.state) }}
              </p>
              <ng-template #editUserState
                ><select class="form-select" [(ngModel)]="orgState">
                  <option
                    *ngFor="let stateCountyDetail of stateCountyDetails"
                    [value]="stateCountyDetail.state"
                  >
                    {{ stateCountyDetail.name }}
                  </option>
                </select>
              </ng-template>
            </td>
            <td>
              <p class="small-txt">Zip Code</p>
              <p *ngIf="!editOrgAddressToggle" class="lbl-value">
                {{ user.organization?.location?.zipcode }}
              </p>
              <input
                *ngIf="editOrgAddressToggle"
                id="zipcode"
                type="text"
                [(ngModel)]="orgZipcode"
                class="input"
              />
            </td>
          </tr>
        </table>
        <div class="tabcard">
          <div class="tab-header">Additional Information</div>
          <div
            class="btndiv"
            *ngIf="!editOrgAdditionalInfoToggle"
            (click)="editOrgAdditionalInfo()"
          >
            <span class="material-icons edit-details">border_color</span>
            Edit
          </div>
          <div
            class="btn-save"
            *ngIf="editOrgAdditionalInfoToggle"
            (click)="saveOrgAdditionalInfo()"
          >
            <span class="material-icons">save</span> Save
          </div>
        </div>
        <table class="address-detail-table">
          <tr>
            <td>
              <p class="small-txt">Website</p>
              <p *ngIf="!editOrgAdditionalInfoToggle" class="lbl-value">
                {{ user.organization?.website }}
              </p>
              <input
                *ngIf="editOrgAdditionalInfoToggle"
                id="orgWebsite"
                type="text"
                [(ngModel)]="orgWebsite"
                class="input"
              />
            </td>
            <td>
              <p class="small-txt">Description</p>
              <p *ngIf="!editOrgAdditionalInfoToggle" class="lbl-value">
                {{ user.organization?.description }}
              </p>
              <input
                *ngIf="editOrgAdditionalInfoToggle"
                id="orgDescription"
                type="text"
                [(ngModel)]="orgDescription"
                class="input"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="servicezones" *ngIf="activeTab == 'servicezones'">
      <div class="tabcontent">
        <p class="psz-text">
          Please input your service capabilities by selecting from the choices
          below under "1. Service Capabilities". Once you have slected your
          service capabilities, you may eneter your preferred service zones (and
          related service capabilities) under "2. Zones".
        </p>
        <div class="tabcard">
          <div class="tab-header">1. Service Capabilities</div>
          <!-- future implementation to enable the service capabilities modal-->
          <!-- <div class="btndiv" (click)="toggleServiceCapabilitiesModal()">
            <span class="material-icons edit-details">border_color</span>
            Edit
          </div> -->
        </div>
        <!-- <div class="insurance-details">
          <div class="txt">7 capabilities selected.</div>
        </div> -->
        <!-- <div class="insurance-details">
          <div class="txt">
            Note: Services that are not location specific (I.e. Report Reviews,
            Report Writing) are not affected by the services nones listed.
          </div>
        </div> -->
        <div class="service-panel-checkbox">
          <div *ngFor="let serviceType of serviceTypes">
            <div class="checkbox">
              <input
                type="checkbox"
                class="checkbox-field"
                [(ngModel)]="serviceType.checked"
                (change)="saveServiceTypeCheckboxChange(serviceType)"
              />
              <label class="checkbox-label">{{
                serviceType.displayName
              }}</label>
            </div>
          </div>
        </div>
        <div class="tabcard">
          <div class="tab-header">2. Zones</div>
          <div
            *ngIf="user.selectedServiceTypes?.length > 0"
            class="add-row"
            (click)="addNewServiceZone()"
          >
            <span class="material-icons">add</span>
            <p class="add-row-text">Add A New Zone</p>
          </div>
        </div>
        <div class="insurance-details">
          <div class="txt mb-10">
            Add a zone and check capabilities from the selected services.
          </div>
        </div>
        <div
          *ngIf="user.selectedServiceTypes?.length > 0 && toggleNewServiceZone"
          class="zones-card-new"
        >
          <div class="zone-actions">
            <div class="btn-save" (click)="saveNewServiceZone()">
              <span class="material-icons">save</span> Save
            </div>
            <div class="btn-cancel" (click)="cancelNewServiceZone()">
              <span class="material-icons cancel-details">close</span>
              Cancel
            </div>
          </div>
          <div class="container">
            <div class="first-block">
              <div class="form-control">
                <label for="exampleFormControlInput1" class="form-label"
                  >State<span class="req">*</span></label
                ><br />
                <select
                  class="form-select"
                  [(ngModel)]="selectedNewZoneState"
                  (ngModelChange)="handleNewZoneStateChange()"
                >
                  <option
                    *ngFor="
                      let serviceZoneStateCountyDetail of serviceZoneStateCountyDetails
                    "
                    [ngValue]="serviceZoneStateCountyDetail"
                  >
                    {{ serviceZoneStateCountyDetail.name }}
                  </option>
                </select>
              </div>
              <div
                *ngIf="selectedNewZoneState.state !== 'NATIONWIDE'"
                class="form-control"
              >
                <label for="exampleFormControlInput1" class="form-label"
                  >County<span class="req">*</span></label
                ><br />
                <mat-select
                  multiple
                  [(value)]="selectedNewCounties"
                  class="form-multi-select"
                  panelClass="form-multi-select-panel"
                >
                  <mat-select-trigger>
                    <mat-select-trigger>
                      {{ selectedNewCounties[0] || "" }}
                      @if ((selectedNewCounties?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{ (selectedNewCounties?.length || 0) - 1 }}
                        {{
                          selectedNewCounties?.length === 2
                            ? "other"
                            : "others"
                        }})
                      </span>
                      }
                    </mat-select-trigger>
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let serviceZoneCounty of serviceZoneCounties"
                    [value]="serviceZoneCounty"
                    class="form-multi-select-option"
                    [disabled]="
                      isOptionDisabled(selectedNewCounties, serviceZoneCounty)
                    "
                  >
                    {{ serviceZoneCounty }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="second-block">
              <div class="txt-heading">Services</div>
              <div class="panel-fields">
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="allNewZoneServiceTypesSelected"
                    (change)="handleSelectAllNewZoneServiceTypes()"
                  />
                  <label>Select All</label>
                </div>
              </div>
              <div class="panel-fields1">
                <div *ngFor="let serviceType of newZoneServiceTypes">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      class="checkbox-field"
                      [(ngModel)]="serviceType.enabledForZone"
                      (change)="handleNewZoneServiceTypeCheckboxChange()"
                    />
                    <label class="checkbox-label">{{
                      serviceType.displayName
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let userServiceZone of user?.serviceZones"
          class="zones-card"
        >
          <div class="zone-actions">
            <div
              *ngIf="
                !userServiceZone.zoneDocuments ||
                userServiceZone.zoneDocuments?.length === 0
              "
              class="zone-actions"
            >
              <div class="zone-doc-txt">*Documents Needed</div>
              <div class="fix-btn" (click)="toggleTab('documents')">Fix</div>
            </div>
            <div
              class="btn-zone-delete"
              *ngIf="!editZoneToggle"
              (click)="removeServiceZone(userServiceZone)"
            >
              <span class="material-icons edit-details">delete_forever</span>
              Remove
            </div>
            <div
              class="btndiv"
              *ngIf="!editZoneToggle"
              (click)="editServiceZone(userServiceZone)"
            >
              <span class="material-icons edit-details">border_color</span>
              Edit
            </div>
            <div
              class="zone-actions"
              *ngIf="
                editZoneToggle &&
                (userServiceZone.state === currentServiceZone.state ||
                  (userServiceZone.nationwide &&
                    currentServiceZone.state === 'NATIONWIDE'))
              "
            >
              <div class="btn-save" (click)="saveUpdatedServiceZone()">
                <span class="material-icons">save</span> Save
              </div>
              <div class="btn-cancel" (click)="cancelUpdateServiceZone()">
                <span class="material-icons cancel-details">close</span>
                Cancel
              </div>
            </div>
          </div>
          <div class="container">
            <div class="first-block">
              <div class="form-control">
                <div
                  *ngIf="
                    editZoneToggle &&
                      editZoneToggle &&
                      (userServiceZone.state === currentServiceZone.state ||
                        (userServiceZone.nationwide &&
                          currentServiceZone.state === 'NATIONWIDE'));
                    else notEditStateCounty
                  "
                >
                  <label for="exampleFormControlInput1" class="form-label"
                    >State<span class="req">*</span></label
                  ><br />
                  <select
                    class="form-select"
                    [(ngModel)]="currentServiceZone.selectedState"
                    (ngModelChange)="handleUpdateZoneStateChange()"
                  >
                    <option
                      *ngFor="
                        let serviceZoneStateCountyDetail of serviceZoneStateCountyDetails
                      "
                      [ngValue]="serviceZoneStateCountyDetail"
                    >
                      {{ serviceZoneStateCountyDetail.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      currentServiceZone.selectedState.state !== 'NATIONWIDE'
                    "
                    class="form-control"
                  >
                    <label for="exampleFormControlInput1" class="form-label"
                      >County<span class="req">*</span></label
                    ><br />
                    <mat-select
                      multiple
                      [(value)]="currentServiceZone.selectedCounties"
                      class="form-multi-select"
                      panelClass="form-multi-select-panel"
                    >
                      <mat-select-trigger>
                        <mat-select-trigger>
                          {{ currentServiceZone.selectedCounties[0] || "" }}
                          @if ((currentServiceZone.selectedCounties?.length ||
                          0) > 1) {
                          <span class="example-additional-selection">
                            (+{{
                              (currentServiceZone.selectedCounties?.length ||
                                0) - 1
                            }}
                            {{
                              currentServiceZone.selectedCounties?.length === 2
                                ? "other"
                                : "others"
                            }})
                          </span>
                          }
                        </mat-select-trigger>
                      </mat-select-trigger>
                      <mat-option
                        *ngFor="
                          let serviceZoneCounty of currentServiceZone
                            .selectedState.counties
                        "
                        [value]="serviceZoneCounty"
                        class="form-multi-select-option"
                        [disabled]="
                          isOptionDisabled(
                            currentServiceZone.selectedCounties,
                            serviceZoneCounty
                          )
                        "
                      >
                        {{ serviceZoneCounty }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <ng-template #notEditStateCounty>
                  <div *ngIf="userServiceZone.nationwide; else notNationwide">
                    <div class="nationwide-txt">Nationwide</div>
                  </div>
                  <ng-template #notNationwide>
                    <div class="first-txt">
                      {{ decodeState(userServiceZone.state) }}
                    </div>
                  </ng-template>
                  <div class="county-txt">
                    {{ formatCounty(userServiceZone.counties) }}
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="second-block">
              <div class="txt-heading">Services</div>
              <div
                *ngIf="
                  editZoneToggle &&
                    editZoneToggle &&
                    (userServiceZone.state === currentServiceZone.state ||
                      (userServiceZone.nationwide &&
                        currentServiceZone.state === 'NATIONWIDE'));
                  else noEditServices
                "
              >
                <div class="panel-fields1">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      class="checkbox-field"
                      [(ngModel)]="allUpdateZoneServiceTypesSelected"
                      (change)="handleSelectAllUpdateZoneServiceTypes()"
                    />
                    <label>Select All</label>
                  </div>
                </div>
                <div class="panel-fields1">
                  <div
                    *ngFor="
                      let serviceType of currentServiceZone.selectedServices
                    "
                  >
                    <div class="checkbox">
                      <input
                        type="checkbox"
                        class="checkbox-field"
                        [(ngModel)]="serviceType.enabledForZone"
                        (change)="handleUpdateZoneServiceTypeCheckboxChange()"
                      />
                      <label class="checkbox-label">{{
                        serviceType.displayName
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noEditServices>
                <div class="panel-fields">
                  <div *ngFor="let userService of userServiceZone.services">
                    <div *ngIf="userService.enabledForZone" class="checkbox">
                      {{ userService.displayName }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="documents" *ngIf="activeTab == 'documents'">
      <div class="tabcontent">
        <div class="profile-documents">
          <div class="zone-actions">
            <div class="documents-dialog__content__form__upload">
              <label for="documents-dialog-file-upload" class="btn"
                ><span class="material-icons">upload</span>Upload</label
              >
              <input
                id="documents-dialog-file-upload"
                type="file"
                multiple
                required
                (change)="uploadProfileDocument($event)"
                accept=".pdf, .txt, .csv, .gif, .png, .tiff, .jpg, .doc, .docx, .xls, .xlsx, .html, .zip"
              />
            </div>
          </div>
          <div class="documents-container">
            <div class="documents-first-block">
              <label class="first-txt">Profile Documents</label>
            </div>
            <div
              class="second-block"
              *ngIf="
                !user.documents || user.documents.length === 0;
                else profileDocuments
              "
            >
              <label class="panel-fields">No Profile Documents</label>
            </div>
            <ng-template #profileDocuments>
              <div
                class="document-second-block"
                *ngFor="let profileDocument of user.documents"
              >
                <div class="card">
                  <div class="card-row">
                    <a (click)="downloadDocument(profileDocument)"
                      ><span class="material-icons">download</span></a
                    >
                  </div>
                  <div class="card-row">
                    {{ profileDocument.fileName }}
                  </div>
                  <div class="card-row">
                    <div
                      class="btn-delete"
                      (click)="deleteProfileDocument(profileDocument.id)"
                    >
                      <span class="material-icons" title="Delete">delete</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="zone-documents-label">Zone Documents</div>
        <div
          class="profile-documents"
          *ngFor="let serviceZone of user.serviceZones; let i = index"
        >
          <div class="zone-document-actions">
            <div
              *ngIf="
                !serviceZone.zoneDocuments ||
                serviceZone.zoneDocuments.length === 0
              "
              class="doc-txt"
            >
              *Documents Needed
            </div>
            <div class="documents-dialog__content__form__upload">
              <label
                [htmlFor]="'zone-documents-dialog-file-upload' + i"
                class="btn"
                [ngClass]="{
                  'doc-need-upload':
                    !serviceZone.zoneDocuments ||
                    serviceZone.zoneDocuments.length === 0
                }"
              >
                <span class="material-icons">upload</span>Upload</label
              >
              <input
                [id]="'zone-documents-dialog-file-upload' + i"
                type="file"
                multiple
                required
                (change)="uploadZoneDocument(serviceZone, $event)"
                accept=".pdf, .txt, .csv, .gif, .png, .tiff, .jpg, .doc, .docx, .xls, .xlsx, .html, .zip"
              />
            </div>
          </div>
          <div class="documents-container">
            <div
              *ngIf="serviceZone.nationwide; else state"
              class="documents-first-block"
            >
              <label class="first-txt">Nationwide</label>
            </div>
            <ng-template #state>
              <div class="documents-first-block">
                <label class="first-txt">{{
                  decodeState(serviceZone.state)
                }}</label>
              </div>
            </ng-template>
            <div
              class="document-second-block"
              *ngIf="
                !serviceZone.zoneDocuments ||
                  serviceZone.zoneDocuments.length === 0;
                else zoneDocuments
              "
            >
              <label class="panel-fields">No Zone Documents</label>
            </div>
            <ng-template #zoneDocuments>
              <div
                class="document-second-block"
                *ngFor="let zoneDocument of serviceZone.zoneDocuments"
              >
                <div class="card">
                  <div class="card-row">
                    <a (click)="downloadDocument(zoneDocument)"
                      ><span class="material-icons">download</span></a
                    >
                  </div>
                  <div class="card-row">
                    {{ zoneDocument.fileName }}
                  </div>
                  <div class="card-row">
                    <div
                      class="btn-delete"
                      (click)="deleteZoneDocument(serviceZone, zoneDocument.id)"
                    >
                      <span class="material-icons" title="Delete">delete</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<service-capabilities-dialog
  [active]="serviceCapabilitiesActive"
  [serviceCapabilities]="serviceTypes"
  [serviceCapabilitiesType]="serviceCapabilitiesType"
  (modalClosed)="toggleServiceCapabilitiesModal()"
  [ngClass]="{ 'documents-dialog--active': serviceCapabilitiesActive }"
></service-capabilities-dialog>
