<div class="container">
  <div class="left-navigation">
    <div class="back-btn" (click)="goBack()">
      <span class="material-icons">arrow_back_ios</span>
      <a class="back-btn">Back</a>
    </div>
    <hr />
    <div class="my-account">My Account</div>
    <div class="left-nav" [routerLink]="'/manageaccount'">
      <span class="material-icons">person</span>
      <a class="left-links">My Profile</a>
    </div>
    <div class="left-nav" [routerLink]="'/settings'">
      <span class="material-icons">settings</span>
      <a class="left-links">Account Settings</a>
    </div>
    <hr />
    <div
      class="left-nav"
      [routerLink]="'/contact'"
      routerLinkActive="router-link-active"
    >
      <span class="material-icons">info</span>
      <a class="left-links">Contact Us</a>
    </div>
    <div class="left-nav">
      <span class="material-icons">logout</span>
      <a (click)="logout()" class="left-links">Logout</a>
    </div>
  </div>
  <div class="content-area">
    <div class="setting-header">
      <h2><span class="material-icons">settings_suggest</span> Settings</h2>
    </div>
    <div class="settings-container">
      <div class="top-blocks">
        <div class="block top-left">
          <h3>Update Password</h3>
          <div class="line"></div>
          <div class="name-fields-container">
            <form [formGroup]="newPassword">
              <div id="new-password">
                <div class="field">
                  <span class="form-label"
                    >Current Password<span class="req">*</span></span
                  >
                  <input
                    id="currentPassword"
                    type="password"
                    formControlName="currentPassword"
                    style="margin-bottom: -0px"
                    maxlength="100"
                  />
                  <div
                    *ngIf="
                      newPassword.controls['currentPassword']?.invalid &&
                      (newPassword.controls['currentPassword'].dirty ||
                        newPassword.controls['currentPassword'].touched)
                    "
                  >
                    <span
                      class="error-message"
                      *ngIf="newPassword.controls.confirmNewPassword.errors?.['required']"
                    >
                      Current password is required.
                    </span>
                  </div>
                </div>
                <div class="field">
                  <span class="form-label"
                    >New Password<span class="req">*</span></span
                  >
                  <input
                    id="newPassword"
                    type="password"
                    formControlName="newPassword"
                    style="margin-bottom: -0px"
                    maxlength="100"
                  />
                  <div>
                    <span class="password-helper-label">
                      Password is case sensitive and must be at least 8
                      characters long.<br />
                      At least 1 <b>lowercase</b> letter.<br />
                      At least 1 <b>uppercase</b> letter.<br />
                      At least 1 <b>numeric</b> character.<br />
                      At least 1 <b>special</b> character.
                    </span>
                  </div>
                  <div
                    *ngIf="
                      newPassword.controls['newPassword']?.invalid &&
                      (newPassword.controls['newPassword'].dirty ||
                        newPassword.controls['newPassword'].touched)
                    "
                  >
                    <div
                      *ngIf="newPassword.controls['newPassword'].errors?.['required']"
                      class="error-message"
                    >
                      New Password is required.
                    </div>
                    <div
                      class="error-message"
                      *ngIf="newPassword.controls['newPassword']?.dirty"
                    >
                      <span
                        class="error-message"
                        *ngIf="!newPassword.controls['newPassword'].value?.match(passwordRegx) && !newPassword.controls['newPassword'].errors?.['required']"
                        >Password does not meet the requirements.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="field">
                  <span class="form-label"
                    >Confirm New Password<span class="req">*</span></span
                  >
                  <input
                    id="confirmNewPassword"
                    type="password"
                    formControlName="confirmNewPassword"
                    maxlength="100"
                  />
                  <div
                    *ngIf="
                      newPassword.controls['confirmNewPassword']?.invalid &&
                      (newPassword.controls['confirmNewPassword'].dirty ||
                        newPassword.controls['confirmNewPassword'].touched)
                    "
                  >
                    <span
                      class="error-message"
                      *ngIf="newPassword.controls.confirmNewPassword.errors?.['required']"
                    >
                      Please confirm your new password.
                    </span>
                  </div>
                  <span
                    class="error-message"
                    *ngIf="
                      newPassword.controls['confirmNewPassword'].value != '' &&
                      newPassword.controls['confirmNewPassword'].value !=
                        newPassword.controls['newPassword'].value
                    "
                  >
                    Passwords do not match.
                  </span>
                </div>
                <span class="form-label"
                  ><span class="req">*</span>Indicates a required field.</span
                >
                <hr />
              </div>
            </form>
          </div>
          <button
            type="button"
            class="btn btn-default"
            (click)="saveNewPassword()"
          >
            <span class="material-icons">save</span> Update
          </button>
        </div>
        <div class="block top-right">
          <h3>Out of Office</h3>
          <div class="line"></div>
          <div class="name-fields-container">
            <div class="field">
              <mat-slide-toggle
                [(ngModel)]="editOutOfOfficeToggle"
                (toggleChange)="editOutOfOffice()"
                color="secondary"
                ><div class="tab-header">Out of Office</div></mat-slide-toggle
              >
            </div>
            <div
              class="field"
              *ngIf="editOutOfOfficeToggle"
              [ngStyle]="{ 'margin-top': '20px' }"
            >
              <label class="form-label"> Start Date: </label>
              <mat-form-field
                appearance="outline"
                [ngStyle]="{ 'background-color': 'white' }"
              >
                <input
                  matInput
                  [matDatepicker]="dp3"
                  [(ngModel)]="outOfOfficeStartDate"
                  (dateChange)="saveOutOfOffice()"
                  readonly
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="dp3"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp3></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="field" *ngIf="editOutOfOfficeToggle">
              <label class="form-label"> End Date: </label>
              <mat-form-field
                appearance="outline"
                [ngStyle]="{ 'background-color': 'white' }"
              >
                <input
                  matInput
                  [matDatepicker]="dp3"
                  [(ngModel)]="outOfOfficeEndDate"
                  (dateChange)="saveOutOfOffice()"
                  readonly
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="dp3"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp3></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="notification-block">
        <h3>Notification</h3>
        <div class="line"></div>
        <p>Please select which notifications you would like to receive.</p>
        <table class="notification-table">
          <tr>
            <td>
              <div class="block">
                <h4>Notify me thru...</h4>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="allNotifiedByEnabled"
                    (change)="handleSelectAllNotifiedBy()"
                  />
                  <label> Select all</label>
                </div>
                <div class="line"></div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.notifyByInApp"
                    (change)="handleNotifiedByChange()"
                  />
                  <label> In-app Notifications</label>
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.notifyByEmail"
                    (change)="handleNotifiedByChange()"
                  />
                  <label> Email Notifications</label>
                </div>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="saveNotifiedBy()"
                >
                  <span class="material-icons">save</span> Save
                </button>
              </div>
            </td>
            <td>
              <div class="block">
                <h4>Invitations</h4>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="allInvitationNotificationsEnabled"
                    (change)="handleSelectAllInvitationNotifications()"
                  />
                  <label> All Notifications</label>
                </div>
                <div class="line"></div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.newInvitationNotification"
                    (change)="handleInvitationNotificationChange()"
                  />
                  <label> New Invitations</label>
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.projectUpdateNotification"
                    (change)="handleInvitationNotificationChange()"
                  />
                  <label>Project Updates</label>
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.cancelledInvitationNotification"
                    (change)="handleInvitationNotificationChange()"
                  />
                  <label>Cancelled</label>
                </div>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="saveInvitationNotifications()"
                >
                  <span class="material-icons">save</span> Save
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="block">
                <h4>Proposals</h4>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="allProposalNotifcationsEnabled"
                    (change)="handleSelectAllProposalNotifications()"
                  />
                  <label> All Notifications</label>
                </div>
                <div class="line"></div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.awardedNotification"
                    (change)="handleProposalNotificationChange()"
                  />
                  <label>Awarded</label>
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.closedNotification"
                    (change)="handleProposalNotificationChange()"
                  />
                  <label>Closed</label>
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.cancelledProposalNotification"
                    (change)="handleProposalNotificationChange()"
                  />
                  <label>Cancelled</label>
                </div>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="saveProposalNotifications()"
                >
                  <span class="material-icons">save</span> Save
                </button>
              </div>
            </td>
            <td>
              <div class="block">
                <h4>Active Projects</h4>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="allActiveProjectNotificationsEnabled"
                    (change)="handleSelectAllActiveProjectNotifications()"
                  />
                  <label> All Notifications</label>
                </div>
                <div class="line"></div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.revisionRequestedNotification"
                    (change)="handleActiveProjectNotificationChange()"
                  />
                  <label>Revisions Requested</label>
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.completedNotification"
                    (change)="handleActiveProjectNotificationChange()"
                  />
                  <label>Completed</label>
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-field"
                    [(ngModel)]="user.cancelledActiveProjectNotification"
                    (change)="handleActiveProjectNotificationChange()"
                  />
                  <label>Cancelled</label>
                </div>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="saveActiveProjectNotifications()"
                >
                  <span class="material-icons">save</span> Save
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
