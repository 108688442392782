<div *ngIf="data?.length > 0; else placeholder">
  <project-history-container
    [data]="data"
    [type]="'project-history'"
  ></project-history-container>
</div>
<ng-template #placeholder>
  <div *ngIf="loading; else notLoading" class="placeholder">
    <p>Loading completed projects...</p>
  </div>
  <ng-template #notLoading>
    <div>
      <button class="back-button" (click)="goBack()">
        <span class="material-icons">keyboard_arrow_left</span>Back
      </button>
      <p class="placeholder">You currently have no completed projects.</p>
    </div>
  </ng-template>
</ng-template>
