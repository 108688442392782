<div *ngIf="data && data.sites?.length > 0; else placeholder">
  <project-overview-container
    [data]="data"
    [enableSubmitAllBids]="enableSubmitAllBids"
    [numberOfBids]="numberOfBids"
    [showAtp]="showAtp"
    [type]="'project-overview'"
  ></project-overview-container>
  <allbids-submitted-dialog
    [active]="bidSubmittedActive"
    (modalClosed)="toggleCloseSubmittedAllBidsModal()"
    [ngClass]="{ 'documents-dialog--active': bidSubmittedActive }"
  ></allbids-submitted-dialog>
  <atp-sign-accepted-dialog
    [active]="isATPSignedAccepted"
    [siteType]="siteType"
    [address]="address"
    (modalClosed)="toggleCloseAtpAcceptedModal()"
    [ngClass]="{ 'documents-dialog--active': isATPSignedAccepted }"
    [modalHeaderTitle]="atpModalHeaderTitle"
    [modalBody]="atpModalBody"
  ></atp-sign-accepted-dialog>
  <decline-success
    [active]="declineSuccessActive"
    [address]="address"
    [siteType]="siteType"
    (modalClosed)="toggleCloseDeclineSuccessModal()"
    [ngClass]="{ 'documents-dialog--active': declineSuccessActive }"
  ></decline-success>
</div>
<ng-template #placeholder>
  <div *ngIf="loading; else notLoading" class="placeholder">
    <p>Loading project overview...</p>
  </div>
  <ng-template #notLoading>
    <div>
      <button class="back-button" (click)="goBack()">
        <span class="material-icons">keyboard_arrow_left</span>Back
      </button>
      <p class="placeholder">No project data available.</p>
    </div>
  </ng-template>
</ng-template>
