<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>
      {{ status === "ACTIVE" ? "Deliverable" : "Revision" }} Submitted For
      Review.
    </h2>
    <br />
    <br />

    <div class="documents-dialog__content__footer">
      <div class="documents-dialog__content__footer__service-type">
        <p class="documents-dialog__content__footer__service-type__label">
          Service Type
        </p>
        <p class="service-type">{{ siteType }}</p>
      </div>

      <div class="documents-dialog__content__footer__service-type">
        <p class="documents-dialog__content__footer__service-type__label">
          Location
        </p>
        <p class="location">
          <span *ngIf="address.streetAddress"
            >{{ address.streetAddress }}<br /></span
          >{{ address.cityStateZip }}
        </p>
      </div>

      <div class="btnalign">
        <button
          class="documents-dialog__content__footer__submit pull-right"
          (click)="closeDialog()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
