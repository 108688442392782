import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'project-history-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './project-history-header.component.html',
  styleUrl: './project-history-header.component.scss',
})
export class ProjectHistoryHeaderComponent {
  @Input() expanded: boolean = false;
  @Input() type: string = '';
  openState: boolean = this.expanded;
  serviceInfoToggle: boolean = false;

  goBack() {
    window.history.back();
  }
}
