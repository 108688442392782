import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private isSignedAtpModalOpenSubject = new BehaviorSubject<boolean>(false);
  isSignedAtpModalOpen = this.isSignedAtpModalOpenSubject.asObservable();

  private isSubmittedAllBidsModalOpenSubject = new BehaviorSubject<boolean>(
    false
  );
  isSubmittedAllBidsModalOpen =
    this.isSubmittedAllBidsModalOpenSubject.asObservable();

  private isSubmittedBidModalOpenSubject = new BehaviorSubject<boolean>(false);
  isSubmittedBidModalOpen = this.isSubmittedBidModalOpenSubject.asObservable();

  private isDeclineModalOpenSubject = new BehaviorSubject<any>({
    siteDetails: {},
    isOpen: false,
  });
  isDeclineModalOpen = this.isDeclineModalOpenSubject.asObservable();

  // prent document dialog from closing on the dashboard when data is refreshed
  private isDocumentModalOpenSubject = new BehaviorSubject<any>({
    siteId: '',
    isOpen: false,
  });
  isDocumentModalOpen = this.isDocumentModalOpenSubject.asObservable();

  private isSubmitDeliverableModalOpenSubject = new BehaviorSubject<any>({
    siteId: '',
    isOpen: false,
  });
  isSubmitDeliverableModalOpen =
    this.isSubmitDeliverableModalOpenSubject.asObservable();

  toggleOpenSignedAtpModal() {
    this.isSignedAtpModalOpenSubject.next(true);
  }

  toggleCloseSignedAtpModal() {
    this.isSignedAtpModalOpenSubject.next(false);
  }

  toggleOpenSubmittedBidModal() {
    this.isSubmittedBidModalOpenSubject.next(true);
  }

  toggleCloseSubmittedBidModal() {
    this.isSubmittedBidModalOpenSubject.next(false);
  }

  toggleOpenSubmittedAllBidsModal() {
    this.isSubmittedAllBidsModalOpenSubject.next(true);
  }

  toggleCloseSubmittedAllBidsModal() {
    this.isSubmittedAllBidsModalOpenSubject.next(false);
  }

  toggleOpenDeclineSuccessModal(siteDetail: any) {
    this.isDeclineModalOpenSubject.next({
      siteDetail: siteDetail,
      isOpen: true,
    });
  }

  toggleCloseDeclineSuccessModal() {
    this.isDeclineModalOpenSubject.next({
      siteDetail: {},
      isOpen: false,
    });
  }

  toogleOpenDocumentModal(siteId: string) {
    this.isDocumentModalOpenSubject.next({
      siteId: siteId,
      isOpen: true,
    });
  }

  toogleCloseDocumentModal() {
    this.isDocumentModalOpenSubject.next({
      siteId: '',
      isOpen: false,
    });
  }

  toogleOpenSubmitDeliverableModal(siteId: string) {
    this.isSubmitDeliverableModalOpenSubject.next({
      siteId: siteId,
      isOpen: true,
    });
  }

  toogleCloseSubmitDeliverableModal() {
    this.isSubmitDeliverableModalOpenSubject.next({
      siteId: '',
      isOpen: false,
    });
  }
}
