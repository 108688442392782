import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProjectService } from '../services/project.service';
import { User } from '../model/user';

@Component({
  standalone: true,
  selector: 'info-navigation',
  imports: [RouterLink, CommonModule],
  templateUrl: './info-navigation.component.html',
  styleUrl: './info-navigation.component.scss',
})
export class InfoNavigation {
  @Input() type: string = '';
  showClearSearchButton: boolean = false;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.projectService.showClearSearchButton.subscribe(
      (showButton: boolean) => (this.showClearSearchButton = showButton)
    );
  }

  clearSearch() {
    this.projectService.clearSearchData(this.user.id ?? '');
    this.projectService.toggleClearSearchButton(false);
  }
}
