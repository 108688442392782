<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>Documents</h2>
    <div
      *ngIf="
        data.proposal.status !== 'BID_WON' &&
        data.proposal.status !== 'BID_LOST' &&
        data.proposal.status !== 'COMPLETED'
      "
      class="documents-dialog__content__form"
    >
      <div class="documents-dialog__content__form__upload">
        <label
          for="documents-dialog-file-upload"
          class="btn"
          (dragover)="onDragOver($event)"
          (drop)="onDrop($event)"
        >
          <span class="material-icons">upload</span>Upload a Document</label
        >
        <input
          id="documents-dialog-file-upload"
          type="file"
          multiple
          required
          (change)="onFileSelect($event)"
          accept=".pdf, .txt, .csv, .gif, .png, .tiff, .jpg, .doc, .docx, .xls, .xlsx, .html, .zip"
        />
      </div>
    </div>
    <ng-container *ngIf="documents?.length > 0">
      <h3>RFP Documents</h3>
      <div class="documents-dialog__content__documents">
        <div
          class="documents-dialog__content__documents__item"
          *ngFor="let document of documents"
        >
          <div class="documents-dialog__content__documents__item__content">
            <p
              class="documents-dialog__content__documents__item__content__title"
            >
              {{ document.fileName }}
            </p>
            @switch (document.type) { @case ('PROPOSAL') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Proposal
            </p>
            } @case ('SITE') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Site
            </p>
            } @case ('DELIVERABLE') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Deliverable
            </p>
            } @case ('REVISION') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Revision
            </p>
            }@case ('ATP') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              ATP
            </p>
            } @case ('SURVEY_CHECKLIST') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Survey Checklist
            </p>
            }}
          </div>
          <div class="documents-dialog__content__documents__item__content_icon">
            <div class="icon">
              <span
                *ngIf="document.type !== 'SURVEY_CHECKLIST'"
                class="material-icons"
                (click)="download(document)"
                >download</span
              >
              <a
                *ngIf="document.type === 'SURVEY_CHECKLIST'"
                href="{{ document.documentPath }}"
                target="_blank"
                [ngStyle]="{ color: 'black' }"
              >
                <span class="material-icons">download</span>
              </a>
            </div>
            <div
              *ngIf="document.type !== 'SITE' && document.type !== 'ATP'"
              class="icon"
            >
              <span
                class="material-icons"
                (click)="deleteExistingDocument(document)"
                >delete</span
              >
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="pendingUploadDocumentRequests?.length > 0">
      <h3>Pending Upload</h3>
      <div class="documents-dialog__content__documents">
        <div
          class="documents-dialog__content__documents__item"
          *ngFor="let pendingDocumentRequest of pendingUploadDocumentRequests"
        >
          <div class="documents-dialog__content__documents__item__content">
            <p
              class="documents-dialog__content__documents__item__content__title"
            >
              {{ pendingDocumentRequest.fileName }}
            </p>
            @switch (pendingDocumentRequest.type) { @case ('PROPOSAL') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Proposal
            </p>
            } @case ('SITE') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Site
            </p>
            } @case ('DELIVERABLE') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Deliverable
            </p>
            } @case ('REVISION') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              Revision
            </p>
            } @case ('ATP') {
            <p
              class="documents-dialog__content__documents__item__content__description"
            >
              ATP
            </p>
            }}
          </div>
          <div class="documents-dialog__content__documents__item__content_icon">
            <div class="icon">
              <span
                class="material-icons"
                (click)="deletePendingDocument(pendingDocumentRequest)"
                >delete</span
              >
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="documents-dialog__content__footer">
      <div class="documents-dialog__content__footer__service-type">
        <p class="documents-dialog__content__footer__service-type__label">
          Service Type
        </p>
        <p class="documents-dialog__content__footer__service-type__text">
          {{ siteType }}
        </p>
      </div>
      <div class="documents-dialog__content__footer__location">
        <p class="documents-dialog__content__footer__location__label">
          Location
        </p>
        <p class="documents-dialog__content__footer__location__text">
          <span *ngIf="address.streetAddress"
            >{{ address.streetAddress }}<br /></span
          >{{ address.cityStateZip }}
        </p>
      </div>
      <button
        (click)="submit()"
        class="documents-dialog__content__footer__submit"
      >
        Save & Close
      </button>
    </div>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
