import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { User } from '../model/user';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [CommonModule, RouterLink, MatIconModule, FormsModule],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  organizationName: string = '';
  message: string = '';

  constructor(
    public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/x.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/linkedin.svg'
      )
    );
  }

  ngOnInit() {
    const user = <User>JSON.parse(localStorage.getItem('user') ?? '');
    if (user) {
      this.firstName = user.firstName ?? '';
      this.lastName = user.lastName ?? '';
      this.email = user.email ?? '';
      this.phone = user.phone ?? '';
      this.organizationName = user.organization?.name;
    }
  }

  sendEmail() {
    if (
      this.firstName?.length === 0 ||
      this.lastName?.length === 0 ||
      this.message?.length === 0
    ) {
      this.toastr.error('Please fill in all the required fields.');
    } else {
      this.userService
        .sendContactUsEmail({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          organizationName: this.organizationName,
          message: this.message,
        })
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            if (apiResponse.message) {
              this.toastr.success(apiResponse.message);
            } else {
              this.toastr.success(
                'Thank you for reaching out to us! We have received your message and our team is currently reviewing it.'
              );
            }
          }
          this.message = '';
        });
    }
  }

  goBack() {
    this.router.navigate(['invitations']);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
  }
}
