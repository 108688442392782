import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProposalService } from '../services/proposal.service';

@Component({
  selector: 'notes-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss',
})
export class NotesComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() data: any = {};
  @Input() bidNotes: string = '';
  @Output() saveBidNotes = new EventEmitter<any>();

  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };

  constructor(private proposalService: ProposalService) {}

  ngOnInit() {
    this.bidNotes = this.data?.proposal?.bidNotes;
  }

  closeDialog() {
    this.active = false;
    this.bidNotes = this.data.proposal.bidNotes;
    this.modalClosed.emit(this.active);
  }

  submit() {
    this.saveBidNotes.emit({
      fieldName: 'bidNotes',
      value: this.bidNotes,
    });
    if (
      this.data.proposal.bid > 0 &&
      this.data.proposal.proposedTurnTime > 0 &&
      this.bidNotes !== this.data.proposal.bidNotes
    ) {
      this.proposalService.editBid({
        ...this.data.proposal,
        bidNotes: this.bidNotes,
      });
    }
    this.closeDialog();
  }
}
