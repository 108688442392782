import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'notification-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './notification-dialog.component.html',
  styleUrl: './notification-dialog.component.scss',
})
export class NotificationDialogComponent {
  @Input() active: boolean = false;
  @Input() readNotifications: any = [];
  @Input() unreadNotifications: any = [];
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() markAllAsRead = new EventEmitter<boolean>();
  @Output() markAsRead = new EventEmitter<any>();

  activeTab: string = 'unread';

  constructor(
    private location: Location,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit() {}

  markAllNotificationsRead() {
    this.markAllAsRead.emit();
  }

  markNotificationRead(notification: any) {
    this.markAsRead.emit(notification);
  }

  toggleTab(tab: string) {
    this.activeTab = tab;
    this.location.replaceState(
      this.location.path().split('?')[0],
      `tab=${tab}`
    );
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
}
