import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { InfoGroup } from '../../info-group/info-group.component';
import { InfoNavigation } from '../../info-navigation/info-navigation.component';
import { User } from '../../model/user';
import { ProjectService } from '../../services/project.service';
import { ProjectHistoryContainerComponent } from '../project-history-container/project-history-container.component';

@Component({
  selector: 'project-history-page',
  standalone: true,
  imports: [CommonModule, ProjectHistoryContainerComponent],
  templateUrl: './project-history-page.component.html',
  styleUrl: './project-history-page.component.scss',
})
export class ProjectHistoryPageComponent {
  data: any = null;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  loading: boolean = true;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.projectService
      .fetchData(this.user.id ?? '')
      .subscribe((result: any) => {
        this.data = result.flatMap((projectDetail: any) =>
          projectDetail.sites.filter(
            (siteDetail: any) =>
              // added the site check for when user does a search
              siteDetail.proposal?.status === 'COMPLETED' && siteDetail.site
          )
        );
        this.loading = false;
      });
  }

  goBack() {
    window.history.back();
  }
}
