<div class="info-group">
  <project-history-header
    [expanded]="expanded"
    [type]="type"
  ></project-history-header>
  <project-history-table
    [projectId]="data.projectId"
    [data]="data"
    [type]="type"
    [expanded]="expanded"
    [index]="index"
    (handleSitesUpdate)="handleSitesUpdate($event)"
  ></project-history-table>
</div>
