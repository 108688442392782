import { Component } from '@angular/core';
import { DeclineSuccessComponent } from '../../decline-success/decline-success.component';
import { AllBidsSubmittedDialogComponent } from '../../allbids-submitted-dialog/allbids-submitted-dialog.component';
import { ProjectOverviewContainerComponent } from '../project-overview-container/project-overview-container.component';
import { CommonModule } from '@angular/common';
import { User } from '../../model/user';
import { ProjectService } from '../../services/project.service';
import { ModalService } from '../../services/modal.service';
import { CommonUtilityServiceService } from '../../services/common-utility-service.service';
import { ActivatedRoute } from '@angular/router';
import { AtpSignAcceptedDialogComponent } from '../../atp-sign-accepted-dialog/atp-sign-accepted-dialog.component';

@Component({
  selector: 'project-overview-page',
  standalone: true,
  imports: [
    CommonModule,
    ProjectOverviewContainerComponent,
    AllBidsSubmittedDialogComponent,
    AtpSignAcceptedDialogComponent,
    DeclineSuccessComponent,
  ],
  templateUrl: './project-overview-page.component.html',
  styleUrl: './project-overview-page.component.scss',
})
export class ProjectOverviewPageComponent {
  data: any = [];
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  bidSubmittedActive: boolean = false;
  declineSuccessActive: boolean = false;
  atpModalHeaderTitle: string = 'ATP Signed & Proposals Accepted';
  atpModalBody: string =
    'You have signed the ATP and proposals are available in Active projects.';
  isATPSignedAccepted: boolean = false;
  address: any = { streetAddress: '', cityStateZip: '' };
  siteType: string = '';
  loading: boolean = true;
  enableSubmitAllBids: boolean = false;
  numberOfBids: number = 0;
  showAtp: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private modalService: ModalService,
    private utilityService: CommonUtilityServiceService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id') || '';
      this.projectService.fetchData(this.user.id ?? '').subscribe((result) => {
        if (result) {
          this.data = result.find(
            (projectDetail: any) => projectDetail.projectId === id
          );
          this.data = {
            ...this.data,
            sites: this.data?.sites?.filter(
              (siteDetail: any) =>
                siteDetail.proposal.status !== 'COMPLETED' &&
                siteDetail.proposal.status !== 'CANCELLED' &&
                siteDetail.proposal.status !== 'DECLINED'
            ),
          };
          this.numberOfBids = this.data?.sites?.filter(
            (siteDetail: any) =>
              siteDetail.proposal.status === 'INVITATION' &&
              siteDetail.proposal.bid > 0 &&
              siteDetail.proposal.proposedTurnTime > 0
          ).length;
          this.enableSubmitAllBids = this.numberOfBids > 0;
          this.loading = false;
          this.showAtp = this.data?.sites?.some(
            (site: { proposal: { status: string } }) =>
              site.proposal?.status === 'BID_WON'
          );
        }
      });
    });
    this.modalService.isSubmittedAllBidsModalOpen.subscribe(
      (isOpen) => (this.bidSubmittedActive = isOpen)
    );
    this.modalService.isSignedAtpModalOpen.subscribe((isOpen) => {
      this.isATPSignedAccepted = isOpen;
    });
    this.modalService.isDeclineModalOpen.subscribe((detail) => {
      if (detail.isOpen) {
        this.declineSuccessActive = true;
        if (detail.siteDetail.site) {
          this.address = this.utilityService.formatAddress(detail.siteDetail);
          this.siteType = detail.siteDetail.site.siteType;
        }
      }
    });
  }

  toggleCloseSubmittedAllBidsModal() {
    this.modalService.toggleCloseSubmittedAllBidsModal();
  }

  toggleCloseDeclineSuccessModal() {
    this.modalService.toggleCloseDeclineSuccessModal();
  }

  toggleCloseAtpAcceptedModal() {
    this.modalService.toggleCloseSignedAtpModal();
  }

  goBack() {
    window.history.back();
  }
}
