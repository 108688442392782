import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DocumentsDialog } from '../../documents-dialog/documents-dialog.component';
import { CommentsComponent } from '../../comments/comments.component';
import { NotesComponent } from '../../notes-dialog/notes.component';
import { MapmodalDialogComponent } from '../../mapmodal-dialog/mapmodal-dialog.component';
import { FormsModule } from '@angular/forms';
import { CommonUtilityServiceService } from '../../services/common-utility-service.service';
import { ProposalService } from '../../services/proposal.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'project-history-table-row',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    DocumentsDialog,
    CommentsComponent,
    NotesComponent,
    MapmodalDialogComponent,
    FormsModule,
  ],
  templateUrl: './project-history-table-row.component.html',
  styleUrl: './project-history-table-row.component.scss',
})
export class ProjectHistoryTableRowComponent {
  @Input() data: any = '';
  @Input() id: string = '';
  @Input() status: string = '';
  @Input() site: number = 0;
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() siteType: string = '';
  @Input() tableA: string = '';
  @Input() item20: any = '';
  @Input() dueDate: string = '';
  @Input() turnTime: number = 0;
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';
  @Input() type: string = '';
  @Input() showStatus: boolean = true;
  @Input() showSite: boolean = true;
  @Input() showAddress: boolean = true;
  @Input() showAcreage: boolean = true;
  @Input() showLandUse: boolean = true;
  @Input() showSiteType: boolean = true;
  @Input() showDueDate: boolean = true;
  @Input() showTableA: boolean = true;
  @Input() showActions: boolean = true;
  @Input() bidNotes: string = '';
  @Input() rowIndex: number = 0;

  @Output() handleSiteUpdate = new EventEmitter<any>();
  @Output() handleUpdateDocuments = new EventEmitter<any>();

  serviceInfoToggle: boolean = false;
  documentsActive: boolean = false;
  editToggle: boolean = false;
  isDeclineActive: boolean = false;
  submitForReviewActive: boolean = false;
  commentsActive: boolean = false;
  notesActive: boolean = false;
  savedActive: boolean = false;
  mapActive: boolean = false;
  showReviewSubmission: boolean = false;
  showRevisionSubmission: boolean = false;
  selectedSiteType: string = '';
  selectedSiteId: string = '';
  tableAItemName: string = '';
  tableAItemValue: string = '';
  currentBidAmount: number = 0;
  currentTurnTime: number = 0;
  documents: any = [];

  constructor(
    private utilityService: CommonUtilityServiceService,
    private proposalService: ProposalService,
    private toastr: ToastrService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.address = this.utilityService.formatAddress(this.data);
    let response = this.utilityService.formatTableAandItem20(this.data);
    if (response) {
      this.item20 = response[0];
      this.tableA = response[1];
    }
    this.currentTurnTime = this.data.proposal.proposedTurnTime;
    this.currentBidAmount = this.data.proposal.bid;
    let documents: any = [];
    if (this.data.site.documents) {
      documents = [...documents, ...this.data.site.documents];
    }
    if (this.data.proposal.documents) {
      documents = [...documents, ...this.data.proposal.documents];
      this.showReviewSubmission = this.data.proposal.documents.some(
        (document: { type: string }) => document.type === 'DELIVERABLE'
      );
      this.showRevisionSubmission = this.data.proposal.documents.some(
        (document: { type: string }) => document.type === 'REVISION'
      );
    }
    this.documents = documents;
    this.modalService.isDocumentModalOpen.subscribe((open) => {
      if (this.data.site.id === open.siteId) {
        this.documentsActive = open.isOpen;
      }
    });
  }

  serviceInfo() {
    this.serviceInfoToggle = !this.serviceInfoToggle;
  }

  toggleCommentsModal() {
    this.commentsActive = !this.commentsActive;
  }

  toggleNotesModal() {
    this.notesActive = !this.notesActive;
  }

  toggleOpenDocumentsModal() {
    this.documentsActive = true;
    this.modalService.toogleOpenDocumentModal(this.data.site.id);
  }

  toggleCloseDocumentsModal() {
    this.documentsActive = false;
    this.modalService.toogleCloseDocumentModal();
  }

  toggleDeclineModal() {
    this.isDeclineActive = !this.isDeclineActive;
  }

  toggleMapModal(siteType: string, siteId: string) {
    this.mapActive = !this.mapActive;
    this.selectedSiteType = siteType;
    this.selectedSiteId = siteId;
  }

  saveBidNote(event: { fieldName: String; value: any }) {
    this.handleSiteUpdate.emit({
      siteId: this.id,
      fieldName: event.fieldName,
      value: event.value,
    });
  }

  updateDocuments(event: { siteDetail: any }) {
    this.handleUpdateDocuments.emit({ updatedSiteDetail: event.siteDetail });
  }
}
