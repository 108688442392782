import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private tokenKey = 'token';

  constructor(private http: HttpClient) {}

  public generateAtp(projectDetail: any, userId: string): Observable<any> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(
      environment.apiUrl + `/site/generate-atp/${userId}`,
      JSON.stringify(projectDetail),
      { headers, responseType: 'json' }
    );
  }

  public signAtp(signedAtp: any, userId: string): Observable<any> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(
      environment.apiUrl + `/site/sign-atp/${userId}`,
      JSON.stringify(signedAtp),
      { headers, responseType: 'json' }
    );
  }

  public cancel(userId: string, projectDetail: any): Observable<any> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(
      environment.apiUrl + `/site/cancel/${userId}`,
      JSON.stringify(projectDetail),
      { headers, responseType: 'json' }
    );
  }
}
