<div class="container">
  <div class="left-navigation">
    <div class="back-btn">
      <span class="material-icons">arrow_back_ios</span>
      <a (click)="goBack()" class="back-btn">Back</a>
    </div>
    <hr />
    <div class="my-account">My Account</div>
    <div class="left-nav" [routerLink]="'/manageaccount'">
      <span class="material-icons">person</span>
      <a class="left-links">My Profile</a>
    </div>
    <div class="left-nav" [routerLink]="'/settings'">
      <span class="material-icons">settings</span>
      <a class="left-links">Account Settings</a>
    </div>
    <hr />
    <div
      class="left-nav"
      [routerLink]="'/contact'"
      routerLinkActive="router-link-active"
    >
      <span class="material-icons">info</span>
      <a class="left-links">Contact Us</a>
    </div>
    <div class="left-nav">
      <span class="material-icons">logout</span>
      <a (click)="logout()" class="left-links">Logout</a>
    </div>
  </div>
  <div class="content-area">
    <div class="contact-us-title">We'd Like To Hear From You</div>
    <div class="contact-us-sub-title">
      Contact us with any questions you may have.
    </div>

    <div class="social-media-icons" style="align-items: center">
      <a
        href="https://www.linkedin.com/company/escreenlogic/"
        target="_blank"
        class="social-icon"
      >
        <mat-icon class="material-icons" svgIcon="linkedin" />
      </a>
      <a
        href="https://www.facebook.com/CREtelligent/"
        target="_blank"
        class="social-icon"
      >
        <mat-icon class="material-icons">facebook</mat-icon>
      </a>
      <a
        href="https://twitter.com/cretelligent/"
        target="_blank"
        class="social-icon"
      >
        <mat-icon class="material-icons" svgIcon="twitter" />
      </a>
    </div>
    <div class="split left">
      <div class="name-fields-container">
        <div class="field">
          <label class="form-label">First Name<span class="req">*</span></label>
          <input
            type="text"
            id="first-name"
            name="first-name"
            [(ngModel)]="firstName"
          />
        </div>
        <div class="field">
          <label for="last-name">Last Name<span class="req">*</span></label>
          <input
            type="text"
            id="last-name"
            name="last-name"
            [(ngModel)]="lastName"
          />
        </div>
      </div>
      <div class="name-fields-container">
        <div class="field">
          <label for="Email">Email<span class="req">*</span></label>
          <input type="text" id="Email" name="Email" [(ngModel)]="email" />
        </div>
        <div class="field">
          <label for="phone-number">Phone Number</label>
          <input
            type="text"
            id="phone-number"
            name="phone-number"
            [(ngModel)]="phone"
          />
        </div>
      </div>
      <div class=".company-name-input">
        <label for="company-name">Organization</label><br />
        <input
          type="text"
          id="company-name"
          name="company-name"
          [(ngModel)]="organizationName"
        />
      </div>
      <div class="field">
        <label for="reason"
          >Please describe your reason for contacting<span class="req"
            >*</span
          ></label
        >
        <textarea
          id="reason"
          name="reason"
          class="text-area"
          rows="10"
          [(ngModel)]="message"
        ></textarea>
      </div>
      <div class="button" (click)="sendEmail()">Submit</div>
    </div>
    <div class="split right">
      <div class="contact-info">
        <div class="location">
          <span class="material-icons">location_on</span>
          <p>11344 Coloma Road, Gold River, CA 95670.</p>
        </div>
        <div class="call">
          <span class="material-icons">call</span>
          <p>(866) 348-1780</p>
        </div>
        <div class="hours">
          <span class="material-icons">access_alarm</span>
          <p>Operation Hours</p>
        </div>
      </div>

      <div class="operation-hours">
        <table>
          <tr>
            <td>Monday</td>
            <td>6:00 AM - 5:00 PM / PST</td>
          </tr>
          <tr>
            <td>Tuesday</td>
            <td>6:00 AM - 5:00 PM / PST</td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td>6:00 AM - 5:00 PM / PST</td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td>6:00 AM - 5:00 PM / PST</td>
          </tr>
          <tr>
            <td>Friday</td>
            <td>6:00 AM - 5:00 PM / PST</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
