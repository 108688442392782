import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, BehaviorSubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectDataSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  private showClearSearchButtonSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  showClearSearchButton = this.showClearSearchButtonSubject.asObservable();
  private projectDetails: any;
  private dataLoaded = false;
  private tokenKey = 'token';

  constructor(private http: HttpClient) {}

  public fetchData(userId: string): Observable<any> {
    if (!this.dataLoaded) {
      this.fetch(userId);
    }
    return this.projectDataSubject.asObservable();
  }

  public fetchSearchData(
    userId: string,
    searchTerm: string,
    addSearchTerm: boolean
  ) {
    this.fetch(userId, searchTerm, addSearchTerm);
  }

  public clearSearchData(userId: string) {
    this.fetch(userId);
  }

  public updateProjectDataSubject(updatedProjectDetail: any) {
    const updatedDetails = this.projectDetails.map((projectDetail: any) => {
      if (updatedProjectDetail.projectId === projectDetail.projectId) {
        let updatedProjectDetailSites = updatedProjectDetail.sites;
        projectDetail.sites.forEach((siteDetail: any) => {
          if (
            !updatedProjectDetailSites.find(
              (s: any) => s.proposal.id === siteDetail.proposal.id
            )
          ) {
            updatedProjectDetailSites.push(siteDetail);
          }
        });
        return {
          ...updatedProjectDetail,
          sites: updatedProjectDetailSites,
        };
      } else {
        return projectDetail;
      }
    });
    this.projectDataSubject.next(updatedDetails);
    this.projectDetails = updatedDetails;
  }

  public updateProjectDataSubjectWithProjectDetail(updatedProjectDetail: any) {
    const updatedDetails = this.projectDetails.map((projectDetail: any) => {
      if (updatedProjectDetail.projectId === projectDetail.projectId) {
        // can't replace project detail with updated project details,
        // need to merge the sites from current and updated details to make sure previously
        // actioned sites are not overridden
        const updatedSiteDetails = projectDetail.sites?.map(
          (siteDetail: any) => {
            const updatedSiteDetail = updatedProjectDetail.sites.find(
              (detail: any) => detail.site.id === siteDetail.site.id
            );
            if (updatedSiteDetail) {
              return updatedSiteDetail;
            } else {
              return siteDetail;
            }
          }
        );
        // need to map exiting and find the new one and return that...
        return {
          ...updatedProjectDetail,
          sites: updatedSiteDetails,
        };
      } else {
        return projectDetail;
      }
    });
    this.projectDataSubject.next(updatedDetails);
    this.projectDetails = updatedDetails;
  }

  /*
   * when navigating to the detail pages, the data passed in is in the shape of:
   * {projectId: UUID, ...etc., sites: [{id: UUID, ...etc.}]}
   */
  public updateProjectDataSubjectWithSingleSite(updatedProjectDetail: any) {
    const updatedSiteDetail = updatedProjectDetail.sites[0];
    const updatedDetails = this.projectDetails.map((projectDetail: any) => {
      if (updatedProjectDetail.projectId === projectDetail.projectId) {
        const updatedSiteDetails = projectDetail.sites.map(
          (siteDetail: any) => {
            if (updatedSiteDetail.site.id === siteDetail.site.id) {
              return updatedSiteDetail;
            } else {
              return siteDetail;
            }
          }
        );
        return { ...updatedProjectDetail, sites: updatedSiteDetails };
      } else {
        return projectDetail;
      }
    });
    this.projectDataSubject.next(updatedDetails);
    this.projectDetails = updatedDetails;
  }

  public toggleClearSearchButton(toggle: boolean) {
    this.showClearSearchButtonSubject.next(toggle);
  }

  private fetch(
    userId: string,
    searchTerm: string = 'none',
    addSearchTerm: boolean = false
  ) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const params = {
      userId: userId,
      searchTerm: searchTerm,
      addSearchTerm: addSearchTerm,
    };
    this.http
      .get(environment.apiUrl + '/project/user-projects', {
        headers,
        params,
      })
      .pipe(
        tap((data) => {
          let projectDetailResponse: any = data;
          this.projectDetails = projectDetailResponse.data;
          this.projectDataSubject.next(this.projectDetails);
          this.dataLoaded = true;
        }),
        shareReplay(1)
      )
      .subscribe();
  }
}
