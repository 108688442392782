<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>
      Submit This Deliverable For
      {{ status === "ACTIVE" ? "Review" : "Revision" }}?
    </h2>
    <p>
      This action will send this
      {{ status === "ACTIVE" ? "deliverable" : "revision" }} for review.
      <br />
      <br />
      Do you wish to continue?
    </p>

    <br />
    <br />

    <div class="documents-dialog__content__footer">
      <div class="documents-dialog__content__footer__service-type">
        <p class="documents-dialog__content__footer__service-type__label">
          Service Type
        </p>
        <p class="service-type">{{ siteType }}</p>
      </div>
      <div class="documents-dialog__content__footer__service-type">
        <p class="documents-dialog__content__footer__service-type__label">
          Location
        </p>
        <p class="location">
          <span *ngIf="address.streetAddress"
            >{{ address.streetAddress }}<br /></span
          >{{ address.cityStateZip }}
        </p>
      </div>
      <div class="btnalign">
        <button class="documents-dialog__content__footer__submit pull-right">
          Submit for {{ status === "ACTIVE" ? "Review" : "Revision" }}
        </button>
        <button class="defaultbtn" (click)="closeDialog()">Save & Close</button>
      </div>
    </div>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
