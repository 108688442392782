<div class="page-header">
  <a routerLink="/invitations"
    ><img src="../../assets/img/logo.png" alt="logo"
  /></a>
  <div class="page-header__search">
    <input
      id="globalSearch"
      type="text"
      class="page-header__search"
      placeholder="Search for an address"
      [(ngModel)]="searchTerm"
      (keyup.enter)="search()"
    />
    <button class="page-header__search__button" (click)="search()">
      <span class="material-icons">search</span>
    </button>
  </div>
  <div class="page-header__buttons">
    <!-- future implementation -->
    <!-- <button class="page-header__buttons__button" (click)="calendar()">
			<div class="page-header__buttons__button__icon"><span class="material-icons">calendar_month</span>
			</div>Calendar
		</button> -->
    <button
      class="page-header__buttons__button"
      (click)="toggleNotificationsModal()"
    >
      <div
        class="page-header__buttons__button__icon"
        [matBadge]="unreadNotifications?.length"
        [matBadgeHidden]="unreadNotifications?.length < 1"
        matBadgeColor="primary"
      >
        <span class="material-icons">notifications</span>
      </div>
      Notifications
    </button>
    <button class="page-header__buttons__button" routerLink="manageaccount">
      <div
        class="page-header__buttons__button"
        *ngIf="userProfilePic; else noPhoto"
      >
        <img [src]="userProfilePic" alt="Profile Picture" />
      </div>
      <ng-template #noPhoto>
        <div class="page-header__buttons__button__icon">
          <span class="material-icons">account_circle</span>
        </div>
      </ng-template>
      My Account
    </button>
  </div>
  <notification-dialog
    [active]="notificationsActive"
    (modalClosed)="toggleNotificationsModal()"
    (markAllAsRead)="markAllAsRead()"
    (markAsRead)="markAsRead($event)"
    [readNotifications]="readNotifications"
    [unreadNotifications]="unreadNotifications"
  ></notification-dialog>
</div>
