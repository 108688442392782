import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { environment } from '../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private tokenKey = 'token';

  constructor(private http: HttpClient) {}
  private userSubject: ReplaySubject<any> = new ReplaySubject<any>(1);

  updateUser(user: User) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.put<any>(
      environment.apiUrl + '/user',
      JSON.stringify(user),
      { headers, responseType: 'json' }
    );
  }

  updateUserEmail(user: User) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.put<any>(
      environment.apiUrl + '/user/email',
      JSON.stringify(user),
      { headers, responseType: 'json' }
    );
  }

  uploadProfilePicture(user: User, picture: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const formData = new FormData();
    formData.set(
      'user',
      new Blob([JSON.stringify(user)], {
        type: 'application/json',
      })
    );
    formData.set('file', picture);
    return this.http.post<any>(
      environment.apiUrl + '/user/profile-picture',
      formData,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  updateServiceCapability(deleted: boolean, user: User, serviceType: string) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    const params = { deleted: deleted, serviceType: serviceType };
    return this.http.post<any>(
      environment.apiUrl + '/user/service-type',
      JSON.stringify(user),
      {
        headers,
        responseType: 'json',
        params: params,
      }
    );
  }

  addNewServiceZone(user: User, serviceZone: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const formData = new FormData();
    formData.set(
      'user',
      new Blob([JSON.stringify(user)], {
        type: 'application/json',
      })
    );
    formData.set(
      'serviceZone',
      new Blob([JSON.stringify(serviceZone)], {
        type: 'application/json',
      })
    );
    return this.http.post<any>(
      environment.apiUrl + '/user/service-zone',
      formData,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  updateServiceZone(
    user: User,
    serviceZone: any,
    serviceZoneIdentifier: string
  ) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const formData = new FormData();
    formData.set(
      'user',
      new Blob([JSON.stringify(user)], {
        type: 'application/json',
      })
    );
    formData.set(
      'serviceZone',
      new Blob([JSON.stringify(serviceZone)], {
        type: 'application/json',
      })
    );
    formData.set('serviceZoneIdentifier', serviceZoneIdentifier);
    return this.http.put<any>(
      environment.apiUrl + '/user/service-zone',
      formData,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  removeServiceZone(userId: string, serviceZoneIdentifier: string) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let params = new HttpParams()
      .set('userId', userId)
      .set('serviceZoneIdentifier', serviceZoneIdentifier);
    return this.http.delete<any>(environment.apiUrl + '/user/service-zone', {
      headers,
      responseType: 'json',
      params: params,
    });
  }

  public updateUserOutOfOfficeDates(user: User): Observable<any> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(
      environment.apiUrl + `/user/out-of-office-dates`,
      JSON.stringify(user),
      {
        headers,
        responseType: 'json',
      }
    );
  }

  public sendContactUsEmail(contactUsRequest: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(
      environment.apiUrl + `/user/contact-us`,
      JSON.stringify(contactUsRequest),
      {
        headers,
        responseType: 'json',
      }
    );
  }

  // be careful when using this, usually want to get the user stored in local storage
  // only doing this to pass data from my account component to the header component
  public getUser() {
    return this.userSubject.asObservable();
  }

  public updateUserSubject(user: User) {
    this.userSubject.next(user);
  }
}
