import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProjectHistoryHeaderComponent } from '../project-history-header/project-history-header.component';
import { ProjectHistoryTableComponent } from '../project-history-table/project-history-table.component';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'project-history-container',
  standalone: true,
  imports: [
    CommonModule,
    ProjectHistoryHeaderComponent,
    ProjectHistoryTableComponent,
  ],
  templateUrl: './project-history-container.component.html',
  styleUrl: './project-history-container.component.scss',
})
export class ProjectHistoryContainerComponent {
  @Input() data: any = '';
  @Input() type: string = '';
  @Input() expanded: boolean = false;
  @Input() index: number = 0;

  constructor(private projectService: ProjectService) {}

  handleSitesUpdate(event: { sites: any }) {
    this.data = { ...this.data, sites: event.sites };
    this.projectService.updateProjectDataSubject(this.data);
  }
}
