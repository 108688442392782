<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <div class="heading">You Have Declined The RFP Invitation.</div>
    <br />
    <br />
    <div class="documents-dialog__content__footer">
      <div class="documents-dialog__content__footer__service-type">
        <div *ngIf="siteType">
          <p class="documents-dialog__content__footer__service-type__label">
            Service Type
          </p>
          <p class="service-type">{{ siteType }}</p>
        </div>
      </div>
      <div class="documents-dialog__content__footer__service-type">
        <div
          *ngIf="
            address.streetAddress?.length !== 0 &&
            address.cityStateZip?.length !== 0
          "
        >
          <p class="documents-dialog__content__footer__service-type__label">
            Location
          </p>
          <p class="location">
            <span *ngIf="address.streetAddress"
              >{{ address.streetAddress }}<br
            /></span>
            {{ address.cityStateZip }}
          </p>
        </div>
      </div>
      <div class="btnalign">
        <button
          class="documents-dialog__content__footer__submit w-120"
          (click)="closeDialog()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
